export function ExtractNumberNameFromStyleId(styleId, t) {
    const cleanedNumber = styleId.replace(/^0+/, '');
    let result;

    if (cleanedNumber.length === 4) {
        const firstPart = cleanedNumber.slice(0, 2);
        const lastPart = cleanedNumber.slice(2);
        result = `${t(`templatePreview.header`)} ${firstPart} ${t("templatesSelector.style")} ${lastPart}`;
    } else if (cleanedNumber.length === 3) {
        const firstPart = cleanedNumber.slice(0, 1);
        const lastPart = cleanedNumber.slice(2);
        result = `${t(`templatePreview.header`)} ${firstPart} ${t("templatesSelector.style")} ${lastPart}`;
    }

    return result;
}
