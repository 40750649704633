import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Label, Spinner } from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DismissRegular, ImageSearch24Filled, ArrowHookUpLeftRegular } from "@fluentui/react-icons";
import { ArrowClockwise24Regular } from "@fluentui/react-icons";
import { ExtractNumberNameFromStyleId } from "../ExtractNumberNameFromStyleId";
import {
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  MessageBarActions,
} from "@fluentui/react-components";
import {
  Toaster,
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  useId,
  Link,
} from "@fluentui/react-components";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  MenuButton
} from "@fluentui/react-components";
import {
  getTheme,
} from '@fluentui/react';
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";
const API_URL = process.env.REACT_APP_API_URL

export const TinyMceDisplay = ({
  styleId,
  selectedValue,
  confirmedTemplateStyleId,
  htmlContent,
  setHtmlContent,
}) => {
  const theme = getTheme();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const backUrl = '/templates/advance-edit'
  const [errorMessage, setErrorMessage] = useState(null);
  const [resetMessage, setResetMessage] = useState(null);
  const companyId = sessionStorage.getItem('companyId') || '';

  const [onEditing, setOnEditing] = useState("")
  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 5000)
  }, [])

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
        setResetMessage(null);
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage, resetMessage]);

  useEffect(() => {
    if (resetMessage) {
      const timer = setTimeout(() => {
        setResetMessage(null);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [resetMessage]);

  const intents = ["warning"];

  const getFile = async (companyId) => {
    try {
      setIsLoading(true);

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }
      // const response = await axios.get(
      //   `http://localhost:7071/api/templates/html?documentType=${selectedValue}`,
      //   {
      //     headers: {
      //       cid: companyId, // Ensure this is a string or number, not undefined
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     },
      //   }
      // );

      const response = await axios.get(
        `${API_URL}/templates/html?documentType=${selectedValue}`,
        {
          headers: {
            cid: companyId, // Ensure this is a string or number, not undefined
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      setHtmlContent(response.data);
      setOnEditing(response.data)
    } catch (error) {
      console.error("Error getting file", error);
    }
  };

  useEffect(() => {
    getFile(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedTemplateStyleId, selectedValue]);

  const handlePreview = async (selectedValue, html) => {
    try {
      setIsLoading(true);
      await PreviewBeforesave(selectedValue, html);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetFile = async () => {
    try {
      setIsLoading(true);

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      // const response = await axios.get(
      //   `http://localhost:7071/api/templates/html?documentType=${selectedValue}`,
      //   {
      //     headers: {
      //       cid: companyId, // Ensure this is a string or number, not undefined
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     },
      //   }
      // );

      const response = await axios.get(
        `${API_URL}/templates/html?documentType=${selectedValue}`,
        {
          headers: {
            cid: companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      setHtmlContent(response.data);
      setOnEditing(response.data)
    } catch (error) {
      console.error("Error getting file", error);
    } finally {
      setIsLoading(false);
      setResetMessage(true);
      EditReset();
      setErrorMessage(null);
    }
  };

  const PreviewBeforesave = async (selectedValue, htmlContent) => {
    try {

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      // const response = await axios.post(
      //   `http://localhost:7073/api/templates/htmltopdf/preview?documentType=${selectedValue}`, htmlContent,
      //   {
      //     headers: {
      //       "Content-Type": "text/html",
      //       "cid": companyId,
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     },
      //     responseType: 'blob'
      //   } // Set responseType to blob to receive blob data
      // );

      const response = await axios.post(
        `${API_URL}/templates/htmltopdf/preview?documentType=${selectedValue}`, htmlContent,
        {
          headers: {
            "Content-Type": "text/html",
            "cid": companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
          responseType: 'blob'
        } // Set responseType to blob to receive blob data
      );

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfURL = URL.createObjectURL(pdfBlob);

      if (response.status >= 200 && response.status < 300) {


        history.replace({
          pathname: "/templates/preview",
          state: {
            name: selectedValue,
            styleId: styleId,
            selectedValue: selectedValue,
            confirmedTemplateStyleId: confirmedTemplateStyleId,
            htmlContent: htmlContent,
            responseFromServer: pdfURL,
            backUrl: backUrl
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setResetMessage(null);
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const handleEditorChange = (content, editor) => {
    if (editorRef.current && editorRef.current.hasFocus()) {
      setHtmlContent(`<!doctype html>
      <html>
      <head>
      <meta charset="utf-8">
      <title>Receipt/Tax Invoice</title>${content}</body>
      </html>`);
    }
  };

  const customStyle = `
     body {
      font-family: 'Arial', sans-serif; 
      font-size: 12px;
      align-self:
      width: 1000px; 
      margin: auto auto; 
      overflow-x: hidden;
    
    }
  `;

  const editorRef = useRef(null);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const EditReset = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("templateTinyPreview.resetSuccess")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );

  return (
    <>
      <Toaster toasterId={toasterId} />
      {
        ((onEditing !== htmlContent)) &&
        <div style={{ width: "100%", color: "black" }}>
          {intents.map((intent) => (
            <MessageBar key={intent} intent={intent}>
              <MessageBarBody>
                <MessageBarTitle> {t("templateTinyPreview.cautionEditRequireSave")}</MessageBarTitle>
                {t("templateTinyPreview.cautionEditRequireSave2")}
              </MessageBarBody>
              <MessageBarActions containerAction={
                <Button appearance="transparent" icon={<DismissRegular />} onClick={() => setOnEditing(htmlContent)} />}
              > </MessageBarActions>
            </MessageBar>
          ))}
        </div>
      }

      {errorMessage && (
        <div style={{ width: "100%", color: "black" }}>
          {intents.map((intent) => (
            <MessageBar key={intent} intent={intent}>
              <MessageBarBody>
                <MessageBarTitle>{t("templateTinyPreview.errorCautionLabel")}</MessageBarTitle>
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  {t("templateTinyPreview.errorCaution5")}
                  <p
                    style={{
                      color: "red",
                      marginTop: "0px",
                      marginBottom: "0px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    {t("templateTinyPreview.errorCaution")}
                  </p>{" "}
                  {t("templateTinyPreview.errorCaution2")}
                  <strong> {t("templateTinyPreview.errorCaution3")}</strong>
                  {t("templateTinyPreview.errorCaution4")}
                </p>
              </MessageBarBody>
              <MessageBarActions containerAction={
                <Button appearance="transparent" icon={<DismissRegular />} onClick={() => setErrorMessage(null)} />}
              > </MessageBarActions>
            </MessageBar>
          ))}
        </div>
      )}

      <h2 styles={{ width: "100%", marginTop: "5%", color: "black" }}>  <Label style={{ color: "black", fontSize: "20px" }}>  {t(`templateTinyPreview.${selectedValue}`)} {ExtractNumberNameFromStyleId(confirmedTemplateStyleId, t)}</Label> </h2>
      <div style={{ width: "85%", display: "flex", justifyContent: "space-between", paddingLeft: '2.5%', paddingRight: '2.5%', marginBottom: "2%", }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <MenuButton
            icon={<ImageSearch24Filled />}
            style={{
              marginRight: "10px",
            }}
            appearance="primary"
            onClick={() => {
              handlePreview(
                selectedValue,
                htmlContent
              );
            }}
            menuIcon={null}
          >
            {t("templatesSelector.preview")}
          </MenuButton>

          <MenuButton
            appearance="secendary"
            onClick={() => {
              history.push({
                pathname: "/templates",
              });
            }}
            style={{
              marginRight: "10px",

            }}
            icon={<ArrowHookUpLeftRegular />}
            menuIcon={null}

          >
            {t("templatesSelector.back")}
          </MenuButton>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1001,
          }}
        >
          {" "}
          <Spinner label={t("templateTinyPreview.loading")} />
        </div>
      ) : (
        <div
          style={{
            width: '85%',
            paddingLeft: '2.5%',
            paddingRight: '2.5%',
            paddingTop: '2.5%',
            paddingBottom: "2.5%",
            background: '#ffffff',
            margin: '0',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "30px",
              marginBottom: "2%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <div
                appearance="secendary"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%"
                }}
              >
                {" "}
                <Dialog>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary" style={{ marginRight: "10px" }} >
                      <ArrowClockwise24Regular /> {t("templateTinyPreview.resetDoc")}
                    </Button>
                  </DialogTrigger>

                  <DialogSurface>
                    <DialogBody>
                      <DialogTitle> {t("templateTinyPreview.title")}</DialogTitle>
                      <DialogContent>
                        {t("templateTinyPreview.areYouSureToReset")}
                      </DialogContent>
                      <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                          <Button onClick={() => { resetFile() }} appearance="primary">
                            {t("templateTinyPreview.reset")}
                          </Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="secondary">
                            {t("templateTinyPreview.cancel")}
                          </Button>
                        </DialogTrigger>
                      </DialogActions>
                    </DialogBody>
                  </DialogSurface>
                </Dialog>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "8px",
              height: `900px`,
              position: "relative",
              width: "100%",
            }}
          >
            <div style={{ overflowY: "auto", position: "relative" }}>
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1001,
                  }}
                >
                  {" "}
                  <Spinner label={t("templateTinyPreview.loading")} />
                </div>) : (

                <Editor
                  apiKey="31xz8igro2agp8bh7luzjvsnr0x852rg8bafgrh1rtsbmmoa"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  init={{
                    content_style: customStyle,
                    min_height: 900,
                    selector: "textarea",
                    remove_linebreaks: true,
                    convert_newlines_to_brs: false,
                    inline_styles: false,
                    fix_table_elements: false,
                    paste_auto_cleanup_on_paste: true,
                    extended_valid_elements:
                      'style,page,page[size="A4"],page[size="A4"][layout="landscape"],page[size="A3"],page[size="A3"][layout="landscape"],page[size="A5"],page[size="A5"][layout="landscape"],&nbsp;',
                    custom_elements:
                      'style,page,page[size="A4"],page[size="A4"][layout="landscape"],page[size="A3"],page[size="A3"][layout="landscape"],page[size="A5"],page[size="A5"][layout="landscape"],&nbsp;',
                    nonbreaking_force_tab: true,
                    toolbar:
                      "save | preview | insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | checklist",
                    plugins: "preview",
                    entity_encoding: "raw",
                    entities: "160,&nbsp,38,amp,60,lt,62,gt",
                    background: "#cccccc"
                  }}
                  value={htmlContent}
                  // Set the fetched HTML content here
                  onEditorChange={handleEditorChange}
                />
              )}
            </div>
          </div>
        </div>)}
    </>
  );
};
