import "./TemplateSelector.css";
export default function TemplatesSelectStructure({ children }) {
  return (
    <>
      <section className="main-pages">
        <div className="control-main-pages">
          {children}
        </div>
      </section>
    </>
  );
}
