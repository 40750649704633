import { TinyMceDisplay } from "./TinyMceDisplay";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function AdvanceEdit() {
  const location = useLocation();
  const data = location.state;
  const [htmlContent, setHtmlContent] = useState("");


  return (
    <section className="main-pages">
      <div className="control-main-pages">
        <TinyMceDisplay
          name={data.name}
          styleId={data.styleId}
          selectedValue={data.selectedValue}
          confirmedTemplateStyleId={data.confirmedTemplateStyleId}
          htmlContent={htmlContent}
          setHtmlContent={setHtmlContent}
        />
      </div>
    </section>

  );
}
