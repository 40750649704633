import TemplateCard from "./TemplateCard";
import { Spinner, Divider } from "@fluentui/react-components";
import {
  getTheme,
} from '@fluentui/react';

import { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Icon
} from '@fluentui/react';
import {
  makeStyles,
  shorthands,
  Tab,
  TabList,
  Subtitle2Stronger,
  Subtitle2
} from "@fluentui/react-components";
import { Label } from "@fluentui/react-components";
import { Default as FluentDropdown } from "./DocumentTypeDropdown";
import {
  ChevronLeftRegular,
  ChevronRightRegular,
  ChevronDoubleLeftRegular,
  ChevronDoubleRightRegular
} from "@fluentui/react-icons";

import CurrentCard from "./CurrentCard";

import { useHistory, useLocation } from "react-router-dom";

import {
  Toaster,
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  useId,
  Link,
} from "@fluentui/react-components";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";
import TemplatesSelectStructure from "./TemplatesSelectStructure";

const API_URL = process.env.REACT_APP_API_URL


const useStyles = makeStyles({
  rootCurrentCard: {
    display: "flex",
    flexDirection: "column",
    color: "darkgrey",
    marginBottom: "20px",
    width: "180px",
    height: "220px",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
  },
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  hovered: {
    backgroundColor: "#ccefff",
  },

  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },

  watermarkImage: {
    width: "80%",
    opacity: 0.2,
  },
});

const Templates = () => {
  const location = useLocation()
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  //  const data = location.state;
  const [selectedValue, setSelectedValue] = useState(
    location?.state?.selectedValue !== undefined ? location.state.selectedValue : "receipt-taxinvoice"
  );
  const [templates, setTemplates] = useState([]);
  const [allTemplates, setAllTemplates] = useState([])
  const [selectedCard, setSelectedCard] = useState("000101");
  const [currentCardNumber, setCurrentCardNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSelectedTemplate, setCurrentSelectedTemplate] = useState(null);

  // const [logoUrl, setLogoUrl] = useState(null);
  // const [signatureUrl, setSignatureUrl] = useState(null);
  const companyId = sessionStorage.getItem('companyId') || '';
  // const getLogoAndSignature = async () => {

  //   let tokenResponse;
  //   try {
  //     tokenResponse = await instance.acquireTokenSilent({
  //       ...silentRequest,
  //       account: account,
  //     });
  //   } catch (error) {
  //     console.error("Error acquiring token: ", error);
  //     instance.acquireTokenRedirect(silentRequest);
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(
  //       ` ${API_URL}/templates/images`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + tokenResponse.accessToken,
  //           "cid": companyId
  //         }
  //       }
  //     );

  //     // Handle logo
  //     if (response.data.logo && response.data.logo.stream && response.data.logo.stream.data) {
  //       const byteArray = new Uint8Array(response.data.logo.stream.data);
  //       const logoBlob = new Blob([byteArray], { type: response.data.logo.contentType });
  //       const logoFile = new File([logoBlob], "logo.png", { type: response.data.logo.contentType });
  //       setLogoUrl(logoFile); // Assuming setLogo is a state setter for the logo
  //     } else {
  //       console.warn("Logo not found.");
  //     }

  //     // Handle signature
  //     if (response.data.signature && response.data.signature.stream && response.data.signature.stream.data) {
  //       const byteArray = new Uint8Array(response.data.signature.stream.data);
  //       const signatureBlob = new Blob([byteArray], { type: response.data.signature.contentType });
  //       const signatureFile = new File([signatureBlob], "signature.png", { type: response.data.signature.contentType });
  //       setSignatureUrl(signatureFile);
  //     } else {
  //       console.warn("Signature not found.");
  //     }





  //   } catch (error) {
  //     console.log(error);

  //   }

  // }

  // useEffect(() => {
  //   getLogoAndSignature()
  //   // eslint-disable-next-line
  // }, [selectedValue])


  const [statusContainer, setStatusContainer] = useState(404)
  const [currentTemplatesList, setCurrentTemplatesList] = useState(null)
  const containerCreate = async (selectedValue) => {

    try {

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }


      // const responseCreate = await axios.get(
      //   `http://localhost:7071/api/templates`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //       "cid": companyId
      //     }
      //   }
      // )

      const responseCreate = await axios.get(
        `${API_URL}/templates`,
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
            "cid": companyId
          }
        }
      )




      console.log("status code right now", responseCreate.data);
      // console.log("status code Message right now",responseCreate.data.message);
      setCurrentTemplatesList(responseCreate.data.selected)
      setStatusContainer(responseCreate.status)
      console.log("currentTemplatesList", currentTemplatesList);

      return true
    } catch (error) {

      console.log("not found container waiting request again!!", error);
      return false
    }
  }




  useEffect(() => {
    setIsLoading(true)

    async function checkContainerEffect() {
      if (selectedValue !== undefined) {
        const isContainer = await containerCreate(selectedValue)

        if (!isContainer) {
          let interval = setInterval(async () => {
            const isCreateContainerComplete = await containerCreate(selectedValue)
            if (isCreateContainerComplete) {
              clearInterval(interval)
            }
          }, 5000);
        }
      }
    }

    checkContainerEffect()

    // eslint-disable-next-line
  }, [selectedValue])


  const fetchData = async (selectedValue) => {
    try {
      setIsLoading(true);
      containerCreate(selectedValue)

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }


      // const response = await axios.get(
      //   `${API_URL}/templates?selectedValue=${selectedValue}`,
      //   {headers:{
      //     Authorization: "Bearer " + tokenResponse.accessToken,
      //     "cid": companyId
      //   }
      //   }
      // );

      console.log("selectedValue right now", selectedValue);

      // const response = await axios.get(
      //   `http://localhost:7071/api/templates/examples?documentType=${selectedValue}`,

      //   {
      //     headers: {
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     }
      //   }
      // );

      const response = await axios.get(
        `${API_URL}/templates/examples?documentType=${selectedValue}`,

        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          }
        }
      );

      const currentSelectedNumber = currentTemplatesList[selectedValue]
      console.log("current Selected Number", currentSelectedNumber);

      // const responseCurrentTemplates = await axios.get(
      //   `http://localhost:7071/api/templates/examples?documentType=${selectedValue}&templateNumber=${currentSelectedNumber}`,

      //   {
      //     headers: {
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     }
      //   }
      // );

      const responseCurrentTemplates = await axios.get(
        `${API_URL}/templates/examples?documentType=${selectedValue}&templateNumber=${currentSelectedNumber}`,

        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          }
        }
      );


      console.log("response new list", response);

      const _templates = response.data.filter(
        (_template) => _template.styleId.slice(-2) === "01"
      );
      console.log("templates origin show up ", _templates);
      console.log("templates origin counting", _templates.length);
      handleChangeDocumentType(_templates)
      setTemplates(_templates);
      setAllTemplates(response.data);

      // console.log("currentTemplatesList", currentTemplatesList[selectedValue]);


      // const filterCurrentTemplates = response.data.filter(
      //   (_templates) => _templates.styleId = currentTemplatesList[selectedValue]
      // )

      // console.log("filterCurrentTemplates right here", filterCurrentTemplates[0]);


      // Check if currentTemplates and selectedValue are valid
      if (responseCurrentTemplates) {

        // const _templatesCurrent = response.data.data.filter(
        //   (_template) => _template.type === _template[selectedValue]
        // );

        // console.log("template current", _templatesCurrent);
        // const filterCurrentTemplates = response.data.filter(
        //   (_templates) => _templates[selectedValue] = currentTemplatesList.selected[selectedValue]
        // )

        // console.log("filterCurrentTemplates right here", filterCurrentTemplates);

        setSelectedCard(responseCurrentTemplates.data[0].styleId);
        setCurrentCardNumber(responseCurrentTemplates.data[0].styleId);

        setCurrentSelectedTemplate(responseCurrentTemplates.data[0]);
        console.log("currentSelectedTemplates Right now!!", responseCurrentTemplates);


        // getLogoAndSignature()
      } else {
        console.warn(`currentTemplates or selectedValue is undefined.`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };




  useEffect(() => {
    if (location?.state?.selectedValue && (location.state.pathname === "back" || location.state.pathname === "save")) {
      console.log("Accessing selectedValue from location state:", location.state.selectedValue);
      console.log("Accessing pathName from location state:", location.state.pathname);

      setSelectedValue(location.state.selectedValue);
    }

    if (location?.state?.currentPage) {
      setCurrentPage(location.state.currentPage);
    }
  }, [location.state]);







  useEffect(() => {

    if (statusContainer === 200) {

      fetchData(selectedValue)
    }

    // eslint-disable-next-line
  }, [selectedValue, statusContainer]);


  //select card background focus template
  const handleSelectCard = (cardId) => {
    setSelectedCard(cardId);
  };

  //find templates  that matching styleId
  const selectedTemplate = allTemplates.find((template) => template.styleId === selectedCard
  );
  const [selectedTemplateConfirm, setSelectedTemplateConfirm] = useState(selectedTemplate);


  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const EditConfirm = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("templatesSelector.saveSuccessfully")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );



  useEffect(() => {
    setSelectedTemplateConfirm(selectedTemplate)
    if (location?.state?.triggerToaster) {
      console.log("triggerToaster", location?.state?.triggerToaster);
      EditConfirm()
    }

    // eslint-disable-next-line
  }, [])


  const handleOnClick = (newValue) => {
    setSelectedValue(newValue);
  };

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);


  const templatesPerPageFirst = 6;
  const templatesPerPageOthers = 6;

  const [totalPages, setTotalPages] = useState(0);

  const handleChangeDocumentType = (newTemplates) => {
    const newTotalTemplates = newTemplates.length;

    const newTotalPages = newTotalTemplates > 0
      ? Math.ceil((newTotalTemplates - templatesPerPageFirst) / templatesPerPageOthers) + 1
      : 1;


    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    } else if (currentPage < 1) {
      setCurrentPage(1);
    }

    setTotalPages(newTotalPages);
  };


  // Calculate indices for slicing the templates array
  const indexOfLastTemplate = currentPage === 1
    ? templatesPerPageFirst
    : templatesPerPageFirst + (currentPage - 2) * templatesPerPageOthers + templatesPerPageOthers;

  const indexOfFirstTemplate = currentPage === 1
    ? 0
    : templatesPerPageFirst + (currentPage - 2) * templatesPerPageOthers;

  // Slice the templates to get the current page's templates
  const currentTemplates = templates.slice(indexOfFirstTemplate, indexOfLastTemplate);


  const handleGoToPage = (page) => {
    setCurrentPage(page);
  };

  const handleShiftButtons = (shift) => {
    const newPage = Math.max(1, Math.min(currentPage + shift, totalPages));
    setCurrentPage(newPage);
    handleGoToPage(newPage); // Call handleGoToPage to update the active tab
  };
  const Tabstyles = useStyles();
  const generatePageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 3);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <Tab
          key={i}
          value={i}
          size="large"
          shape="square"
          onClick={() => handleGoToPage(i)}
        >
          {i}
        </Tab>
      );
    }

    return pageButtons;
  };

  const handleShiftTabList = (shift) => {
    const newActiveTab = Math.max(1, Math.min(currentPage + shift, totalPages));
    setCurrentPage(newActiveTab);
  };


  const GotoSelectStyles = (styleId) => {
    console.log("style id current ", styleId);
    setSelectedCard(styleId)

    history.push({
      pathname: "/templates/example",
      state: {
        selectedTemplateConfirm: selectedTemplate,
        selectedTemplate: selectedTemplate,
        templates: templates,
        searchId: selectedTemplate.styleId,
        nameTemplate: selectedTemplate.type,
        title: selectedTemplate.title,
        description: selectedTemplate.description,
        selectedValue: selectedValue,
        picture: selectedTemplate.picture,
        isLoading: isLoading,
        allTemplates: allTemplates,
        currentPage: currentPage,
        // companyLogo: logoUrl,
        // companySignature: signatureUrl,
        currentCardNumber: currentCardNumber,
      }
    });

  }


  useEffect(() => {
    console.log("test data inside currentSelectedTemplates");
    if (currentSelectedTemplate) {
      console.log("currentSelectedTemplate", currentSelectedTemplate);

      setSelectedCard(currentSelectedTemplate.styleId);
      // setSelectedCard(currentSelectedTemplate);
      console.log("selected Card", selectedCard);

    }


    // eslint-disable-next-line
  }, [currentSelectedTemplate])

  // const EditConfirm = () =>
  //     dispatchToast(
  //       <Toast>
  //         <ToastTitle
  //           action={
  //             <ToastTrigger>
  //               <Link>Dismiss</Link>
  //             </ToastTrigger>
  //           }
  //         >
  //           {t("requestFormAdminFormEditor.saveSuccessfully")}
  //         </ToastTitle>
  //       </Toast>,
  //       { intent: "success" }
  //     );

  // useEffect(()=>{
  //   if (data?.previewpath){
  //   EditConfirm();
  // }

  // },[])


  const theme = getTheme();
  const history = useHistory();
  return (
    <>
      <TemplatesSelectStructure>
        <Toaster toasterId={toasterId} />
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1001,
            }}
          >
            {/* Use the Spinner component */}
            <Spinner label={t("templatesSelector.loading")} />
            {/* <Toaster style={{zIndex:1002}} toasterId={toasterId} /> */}
          </div>

        ) : (
          <>

            <div style={{ width: "1000px" }}>

              {/* <Toaster style={{zIndex:1002}} toasterId={toasterId} /> */}
              <h2 styles={{ width: "100%", marginTop: "5%", color: "black" }} > <Icon iconName="FileTemplate" style={{ color: '#106ebe' }} /> <Label style={{ color: 'black', fontSize: "22px" }}> {t(`templatePreview.header`)} </Label> </h2>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                height: "auto",
                position: "relative",
                marginTop: "0px",
                width: "1000px",
                background: '#ffffff',
                boxShadow: theme.effects.elevation4,
                marginBottom: "5%"
              }}
            >


              <div style={{ width: "100%", display: "flex", flexDirection: "column" }} >

                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", position: "relative" }}>
                  <div style={{ width: "50%", marginLeft: "4%" }}>
                    <div style={{ marginTop: "2%" }}>
                      <FluentDropdown
                        onClick={handleOnClick}
                        selectedValue={selectedValue} />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: "50%", alignItems: "end", marginRight: "6%" }}>
                    <div style={{ marginTop: "2%", color: 'black' }}>
                      <Subtitle2Stronger>{t("templatesSelector.youCanPickTemplates")}  {templates.length} {t("templatesSelector.formats")}</Subtitle2Stronger>
                    </div>
                    <div style={{ marginTop: "2%", color: 'black' }}>
                      <Subtitle2>{t("templatesSelector.page")} {currentPage}/{totalPages}</Subtitle2>
                    </div>
                  </div>


                  <div style={{ position: "absolute", right: "6%", color: "black", bottom: "1%" }}>  </div>

                </div>



                <Divider style={{ marginBottom: "1%", marginTop: "1%", width: "95%", alignSelf: "center" }} ></Divider>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)", // 4 columns per row
                    justifyItems: "center",
                    position: "relative",
                    height: "520px",
                    width: "92%",
                    alignSelf: "center",

                  }}
                >
                  <>
                    {/* First line with empty first column */}
                    <div
                      style={{
                        gridColumn: "1 / span 1", // Place in the first column
                        gridRow: "1 / span 2", // Span 2 rows
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        // alignItems: "center",
                        width: "300px",
                        height: "420px",
                        borderRadius: "0.375rem",
                        color: "black",
                        cursor: "pointer",

                      }}
                    >
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        currentSelectedTemplate && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",


                            }}
                            onClick={() => handleShiftButtons(-1)}
                          // onMouseEnter={() => setIsHoveredLeft(true)}
                          // onMouseLeave={() => setIsHoveredLeft(false)}
                          >
                            <CurrentCard
                              templates={currentSelectedTemplate}
                              onSelect={handleSelectCard}
                              isSelected={currentSelectedTemplate.styleId === selectedCard}
                              // selectedValue={currentSelectedTemplate.type}
                              selectedValue={selectedValue}
                              pictureUrl={currentSelectedTemplate?.picture}

                              history={history}
                              confirmedTemplateStyleId={currentSelectedTemplate.styleId}
                            // confirmedTemplateStyleId={confirmedTemplateStyleId}
                            />
                            <div style={{ textAlign: "center", marginTop: "5px", width: "380px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "relative",
                                  color: "black",
                                }}
                              >
                                {/* <Body2  size="400">{t(`templateTinyPreview.${selectedTemplate.type}`)}</Body2>   */}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "relative",
                                  color: "black",
                                  fontSize: "17px"
                                }}
                              >
                                {/* <Body2> {t("templatesSelector.template")} {extractNumberFromStyleId(confirmedTemplateStyleId)} </Body2> */}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    {/* Other 3 templates in the first line */}
                    {currentTemplates.slice(0, 3).map((template, index) => (
                      <div key={template.id} style={{ gridColumn: `${index + 2} / span 1` }}>
                        <TemplateCard
                          template={template}
                          onSelect={handleSelectCard}
                          isSelected={template.styleId === selectedCard}
                          selectedValue={selectedValue}
                          pictureUrl={template.picture}

                          currentSelectedTemplate={currentSelectedTemplate}
                          selectedTemplateConfirm={selectedTemplateConfirm}
                          GotoSelectStyles={GotoSelectStyles}
                          setSelectedCard={setSelectedCard}
                        />
                      </div>
                    ))}

                    {/* Second line (3 templates) */}
                    {currentTemplates.slice(3, 6).map((template, index) => (
                      <div key={template.id} style={{ gridColumn: `${index + 2} / span 1` }}>
                        <TemplateCard
                          template={template}
                          onSelect={handleSelectCard}
                          isSelected={template.styleId === selectedCard}
                          selectedValue={selectedValue}
                          pictureUrl={template.picture}

                          currentSelectedTemplate={currentSelectedTemplate}
                          selectedTemplateConfirm={selectedTemplateConfirm}
                          GotoSelectStyles={GotoSelectStyles}
                          setSelectedCard={setSelectedCard}
                        />
                      </div>
                    ))}
                  </>


                </div>

                <div style={{ display: "flex", alignSelf: "center", width: "92%", marginBottom: "2px" }}>
                  <div style={{ width: "35%" }}>

                  </div>
                  <div
                    // style={{
                    //   textAlign: "center",
                    //   marginTop: "3.5%",
                    //   height: "40px",
                    //   width:"65%"
                    //  }}

                    style={{ width: "65%", marginBottom: "5px" }}
                  >
                    <div className={Tabstyles.paginationContainer}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <TabList
                            // defaultSelectedValue={currentPage}
                            selectedValue={currentPage}

                          >
                            {" "}
                            <Tab onClick={() => setCurrentPage(1)}>
                              <ChevronDoubleLeftRegular style={{ fontSize: "25px" }} />
                            </Tab>
                            <Tab onClick={() => handleShiftTabList(-1)}>
                              <ChevronLeftRegular style={{ fontSize: "25px" }} />
                            </Tab>
                            {generatePageButtons()}
                            <Tab onClick={() => handleShiftTabList(1)}>
                              <ChevronRightRegular style={{ fontSize: "25px" }} />
                            </Tab>
                            <Tab onClick={() => setCurrentPage(totalPages)} >
                              <ChevronDoubleRightRegular style={{ fontSize: "25px" }} />
                            </Tab>
                          </TabList>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </>
        )}
      </TemplatesSelectStructure>
    </>
  );
};

export default Templates