import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { useLocation, useHistory } from 'react-router-dom';

import {
  TextField,
  PrimaryButton,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  ShimmerElementType,
  Icon,
  Toggle,
  Spinner,
  MessageBar,
  MessageBarType,
  Separator,
  DefaultButton,
} from '@fluentui/react';

import { getProvinceList, getDistrictList, getSubDistrictList, getProvince, getDistrict, getSubDistrict } from '../../../providers/AreaData';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const stackTokens = { childrenGap: 10 };

const dropdownStyles = {
  dropdown: { width: 450 },
};

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
};

const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const shimmerRowHeight32 = [{ type: ShimmerElementType.line, height: 32, width: 410 }];

const Companies = () => {
  // console.log('Re render');
  const location = useLocation();
  const history = useHistory();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [address, setAddress] = useState('');
  const [streetPrefix, setStreetPrefix] = useState('');
  const [streetName, setStreetName] = useState('');
  const [provincePrefix, setProvincePrefix] = useState('');
  const [province, setProvince] = useState({ key: '', text: '' });

  const [districtPrefix, setDistrictPrefix] = useState('');
  const [district, setDistrict] = useState({ key: '', text: '' });
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictPrefix, setSubDistrictPrefix] = useState('');
  const [subDistrict, setSubDistrict] = useState({ key: '', text: '' });
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [postcode, setPostcode] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [taxId, setTaxId] = useState('');
  const [branch, setBranch] = useState('00000');
  const [branchNumber, setBranchNumber] = useState('00000');
  const [showBranchNumber, setShowBranchNumber] = useState(false);
  const [branchText, setBranchText] = useState('สำนักงานใหญ่');
  const [branchDisplay, setBranchDisplay] = useState([
    { key: '00000', text: 'สำนักงานใหญ่' },
    { key: 'branchNumber', text: 'สาขาเลขที่' },
  ]);

  const [showMessageBarSearchError, setShowMessageBarSearchError] = useState(false)
  const [showMessageBarSearchNotFound, setShowMessageBarSearchNotFound] = useState(false)

  let taxIdRef = useRef(null);
  let branchNumberRef = useRef(null);

  const [buttonClick, setButtonClick] = useState(false);
  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);

  const [language, setLanguage] = useState('th');

  const provinceList = getProvinceList(language);

  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [isSearchCompleate, setIsSearchCompleate] = useState(true);

  const [registerVat, setRegisterVat] = useState(true);

  //Error Message
  const [provinceErrMsg, setProvinceErrMsg] = useState('');
  const [districtErrMsg, setDistrictErrMsg] = useState('');
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState('');

  const DropdownErrMsg = t('company.dropdownErrMsg');

  const phoneRegEx = /^0[1-9][0-9]{1,13}$/;
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [companyDetailData, setCompanyDetailData] = useState({});

  const backUrl = '/etax/documents/all'

  const handleCancel = (e) => {
    e.preventDefault();

    history.push({
      pathname: backUrl,
      state: {},
    });
  };

  useEffect(() => {
    console.log('userEffect retrieve company data!');

    async function fetchCompanyData() {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log("response fetch company", response);
              console.log('Company data: ', response.data);

              let _cid = ""
              if (response.data[0]) {
                if (sessionStorage.getItem("companyId") && response.data.find(_company => _company.id === sessionStorage.getItem("companyId"))) {
                  _cid = sessionStorage.getItem("companyId")
                  
                  const _company = response.data.find(_company => _company.id === sessionStorage.getItem("companyId"))
                  setId(_company.id);
                  setName(_company.name);
                  setBuildingNumber(_company.buildingNumber);
                  setAddress(_company.address);
                  setStreetPrefix(_company.streetPrefix);
                  setStreetName(_company.streetName);
                  setProvincePrefix(_company.provincePrefix);
                  setProvince({
                    key: _company.provinceCode,
                    text: _company.provinceName,
                  });

                  setDistrictList(getDistrictList(_company.provinceCode, language));
                  setDistrictPrefix(_company.districtPrefix);
                  setDistrict({
                    key: _company.districtCode,
                    text: _company.districtName,
                  });

                  setSubDistrictList(getSubDistrictList(_company.districtCode, language));
                  setSubDistrictPrefix(_company.subDistrictPrefix);
                  setSubDistrict({
                    key: _company.subDistrictCode,
                    text: _company.subDistrictName,
                  });

                  setPostcode(_company.postcode);
                  setPhone(_company.phone);
                  setEmail(_company.email);
                  setTaxId(_company.taxId);
                  setBranchNumber(_company.branchNumber);
                  setBranchText(_company.branchText);

                  setLanguage(_company.language);
                  setRegisterVat(_company.registerVat);

                  if (_company.branchNumber === '00000') {
                    setBranch('00000');
                    setShowBranchNumber(false);
                  } else {
                    setBranch('branchNumber');
                    setShowBranchNumber(true);
                  }

                  console.log('Branch number: ', branchNumber);
                } else {
                  _cid = response.data[0].id

                  setId(response.data[0].id);
                  setName(response.data[0].name);
                  setBuildingNumber(response.data[0].buildingNumber);
                  setAddress(response.data[0].address);
                  setStreetPrefix(response.data[0].streetPrefix);
                  setStreetName(response.data[0].streetName);
                  setProvincePrefix(response.data[0].provincePrefix);
                  setProvince({
                    key: response.data[0].provinceCode,
                    text: response.data[0].provinceName,
                  });

                  setDistrictList(getDistrictList(response.data[0].provinceCode, language));
                  setDistrictPrefix(response.data[0].districtPrefix);
                  setDistrict({
                    key: response.data[0].districtCode,
                    text: response.data[0].districtName,
                  });

                  setSubDistrictList(getSubDistrictList(response.data[0].districtCode, language));
                  setSubDistrictPrefix(response.data[0].subDistrictPrefix);
                  setSubDistrict({
                    key: response.data[0].subDistrictCode,
                    text: response.data[0].subDistrictName,
                  });

                  setPostcode(response.data[0].postcode);
                  setPhone(response.data[0].phone);
                  setEmail(response.data[0].email);
                  setTaxId(response.data[0].taxId);
                  setBranchNumber(response.data[0].branchNumber);
                  setBranchText(response.data[0].branchText);

                  setLanguage(response.data[0].language);
                  setRegisterVat(response.data[0].registerVat);

                  if (response.data[0].branchNumber === '00000') {
                    setBranch('00000');
                    setShowBranchNumber(false);
                  } else {
                    setBranch('branchNumber');
                    setShowBranchNumber(true);
                  }

                  console.log('Branch number: ', branchNumber);
                }
              }

              if (_cid) {
                axios
                .get(API_URL + '/companies-details', {
                    headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': _cid
                    },
                })
                .then((companiesDetailsResponse) => {
                    console.log('companiesDetailsResponse: ', companiesDetailsResponse);
                    if (companiesDetailsResponse.data) {
                      setCompanyDetailData(companiesDetailsResponse.data)
                    }

                    setIsLoadComplete(true);
                })
                .catch((error) => {
                  console.log(error);
                })
              } else {
                setIsLoadComplete(true);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // fetchCompanyData();

    if (location.state && location.state.command) {
      const { command } = location.state

      console.log("command", command)
      setIsLoadComplete(true);
    } else {
      fetchCompanyData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Language Effect: ', language);

    if (language != null) {
      setProvince(getProvince(province.key, language));
      setDistrict(getDistrict(district.key, language));
      setDistrictList(getDistrictList(province.key, language));
      setSubDistrict(getSubDistrict(subDistrict.key, language));
      setSubDistrictList(getSubDistrictList(district.key, language));

      handleBranch(branchNumber, language);
    }

    if (language === 'en') {
      setSubDistrictPrefix('');
      setDistrictPrefix('');
      setProvincePrefix('');
      setStreetPrefix('');
    } else {
      if (province.key === '10') {
        setSubDistrictPrefix('แขวง');
        setDistrictPrefix('เขต');
        setProvincePrefix('');
      } else if (province.key) {
        setSubDistrictPrefix('ต.');
        setDistrictPrefix('อ.');
        setProvincePrefix('จ.');
      } else {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      }
      if (streetName) {
        setStreetPrefix('ถ.');
      } else {
        setStreetPrefix('');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  function handleSearch() {
    if (taxId.length !== 13) return;

    setIsSearchCompleate(false);
    setShowMessageBarSearchError(false)
    setShowMessageBarSearchNotFound(false)

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/rd/vat/' + taxId + (branchNumber ? `?branch=${branchNumber}` : ''), {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
            },
            timeout: 10000
          })
          .then((response) => {
            console.log('response from rd vat', response);
            if (!response.data.msgerr[0]) {
              setRegisterVat(true);

              if (response.data.BranchNumber[0] === 0) {
                if (language === 'th') {
                  setBranchText('สำนักงานใหญ่');
                } else {
                  setBranchText('Head office');
                }
                setBranchNumber('00000');
              } else if (response.data.BranchNumber[0] !== '-') {
                if (language === 'th') {
                  setBranchText('สาขา ' + String(response.data.BranchNumber[0]).padStart(5, '0'));
                } else {
                  setBranchText('Branch ' + String(response.data.BranchNumber[0]).padStart(5, '0'));
                }
                setBranchNumber(String(response.data.BranchNumber[0]).padStart(5, '0'));
              }

              let _branchTitleName = response.data.BranchTitleName[0].trim()
              if (response.data.BranchTitleName[0].trim() === '-') {
                _branchTitleName = response.data.TitleName[0]
              }

              setName(_branchTitleName + ' ' + response.data.Name[0].trim().substring(0, 140));
              setBuildingNumber(response.data.HouseNumber[0].trim().substring(0, 16));

              let _address = '';

              if (response.data.BuildingName[0].trim() !== '-') {
                _address = _address + response.data.BuildingName[0].trim() + ' ';
              }

              if (response.data.FloorNumber[0].trim() !== '-') {
                _address = _address + 'ชั้น ' + response.data.FloorNumber[0].trim() + ' ';
              }

              if (response.data.RoomNumber[0].trim() !== '-') {
                _address = _address + 'ห้อง ' + response.data.RoomNumber[0].trim() + ' ';
              }

              if (response.data.MooNumber[0].trim() !== '-') {
                _address = _address + 'หมู่ ' + response.data.MooNumber[0].trim() + ' ';
              }

              if (response.data.SoiName[0].trim() !== '-') {
                _address = _address + 'ซอย ' + response.data.SoiName[0].trim() + ' ';
              }

              if (response.data.VillageName[0].trim() !== '-') {
                _address = _address + 'หมู่บ้าน ' + response.data.VillageName[0].trim() + ' ';
              }
              setAddress(_address.trim().substring(0, 256));

              setStreetPrefix(response.data.StreetName[0].trim() !== '-' ? 'ถ.' : '');
              setStreetName(response.data.StreetName[0].trim() !== '-' ? response.data.StreetName[0].trim().substring(0, 70) : '');

              provinceList.forEach((province) => {
                if (province.text === (response.data.Province[0]).trim()) {
                  setProvince({
                    key: province.key,
                    text: province.text,
                  });

                  if (province.key === '10') {
                    setSubDistrictPrefix('แขวง');
                    setDistrictPrefix('เขต');
                    setProvincePrefix('');
                  } else if (province.key) {
                    setSubDistrictPrefix('ต.');
                    setDistrictPrefix('อ.');
                    setProvincePrefix('จ.');
                  } else {
                    setSubDistrictPrefix('');
                    setDistrictPrefix('');
                    setProvincePrefix('');
                  }

                  let districtList = getDistrictList(province.key, language);

                  setDistrictList(districtList);
                  districtList.forEach((district) => {
                    if (district.text === (response.data.Amphur[0]).trim()) {
                      setDistrict({
                        key: district.key,
                        text: district.text,
                      });

                      let subDistrictList = getSubDistrictList(district.key, language);
                      setSubDistrictList(subDistrictList);
                      subDistrictList.forEach((subDistrict) => {
                        if (subDistrict.text === (response.data.Thambol[0]).trim()) {
                          setSubDistrict({
                            key: subDistrict.key,
                            text: subDistrict.text,
                          });
                        }
                      });
                    }
                  });
                }
              });

              setPostcode(response.data.PostCode[0].trim().substring(0, 16));

              setIsSearchCompleate(true);
            } else {
              setShowMessageBarSearchNotFound(true)

              setIsSearchCompleate(true);
            }
          }).catch((err) => {
            setShowMessageBarSearchError(true)

            if (axios.isCancel(err)) {
              console.log('Request timed out');

              setIsSearchCompleate(true);
            } else {
              console.error('Error:', err);

              setIsSearchCompleate(true);
            }
          })
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();

    let isCorrect = true;

    if (province.key === undefined || province.key === null || province.key === '') {
      isCorrect = false;
      setProvinceErrMsg(DropdownErrMsg);
    }

    if (district.key === undefined || district.key === null || district.key === '') {
      isCorrect = false;
      setDistrictErrMsg(DropdownErrMsg);
    }

    if (subDistrict.key === undefined || subDistrict.key === null || subDistrict.key === '') {
      isCorrect = false;
      setSubDistrictErrMsg(DropdownErrMsg);
    }

    if (taxId.length !== 13) {
      isCorrect = false;
      taxIdRef.focus();
    }

    if (branchNumber.length !== 5) {
      console.log('branchNumber length: ', branchNumber.length);
      isCorrect = false;
      branchNumberRef.focus();
    }

    if (phoneNumberError) {
      isCorrect = false;
    }

    setButtonClick(true);
    setShowMessageBarSuccess(false);
    setShowMessageBarError(false);

    console.log(
      'Company:',
      name,
      '\nid:',
      id,
      '\nbuildingNumber:',
      buildingNumber,
      '\naddress:',
      address,
      '\nProvince:',
      province,
      '\nDistrict:',
      district,
      '\nSubDistrict:',
      subDistrict,
      '\nPostCode:',
      postcode,
      '\nPhone:',
      phone,
      '\nTaxId:',
      taxId,
      '\nBranch:',
      branchNumber
    );

    if (isCorrect) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          if (id !== '') {
            axios
              .put(
                API_URL + '/etax/companies/' + id,
                {
                  address: address,
                  branchNumber: branchNumber,
                  branchText: branchText,
                  buildingNumber: buildingNumber,
                  districtCode: district.key,
                  districtName: district.text,
                  districtPrefix: districtPrefix,
                  email: email,
                  name: name,
                  phone: phone,
                  phoneCountryCode: '+66',
                  postcode: postcode,
                  provinceCode: province.key,
                  provinceName: province.text,
                  provincePrefix: provincePrefix,
                  streetName: streetName,
                  streetPrefix: streetPrefix,
                  subDistrictCode: subDistrict.key,
                  subDistrictName: subDistrict.text,
                  subDistrictPrefix: subDistrictPrefix,
                  taxId: taxId,
                  taxNumberType: 'TXID',
                  language: language,
                  registerVat: registerVat,
                  unstructure: false,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  console.log(response);
                  setButtonClick(false);
                  setShowMessageBarSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setButtonClick(false);
                  setShowMessageBarError(true);
                  window.scrollTo(0, 0);
                }
              );
          } else {
            axios
              .post(
                API_URL + '/etax/companies',
                {
                  address: address,
                  branchNumber: branchNumber,
                  branchText: branchText,
                  buildingNumber: buildingNumber,
                  districtCode: district.key,
                  districtName: district.text,
                  districtPrefix: districtPrefix,
                  email: email,
                  name: name,
                  phone: phone,
                  phoneCountryCode: '+66',
                  postcode: postcode,
                  provinceCode: province.key,
                  provinceName: province.text,
                  provincePrefix: provincePrefix,
                  streetName: streetName,
                  streetPrefix: streetPrefix,
                  subDistrictCode: subDistrict.key,
                  subDistrictName: subDistrict.text,
                  subDistrictPrefix: subDistrictPrefix,
                  taxId: taxId,
                  taxNumberType: 'TXID',
                  language: language,
                  registerVat: registerVat,
                  unstructure: false,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  console.log(response);
                  setButtonClick(false);
                  setShowMessageBarSuccess(true);
                  window.scrollTo(0, 0);

                  sessionStorage.setItem("companyId", response.data.id);
                  window.location.replace('/etax/documents/all')
                  // window.location.href = '/etax/documents/all';
                },
                (error) => {
                  console.log(error);
                  setButtonClick(false);
                  setShowMessageBarError(true);
                  window.scrollTo(0, 0);
                }
              );
          }
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setButtonClick(false);
    }
  }

  const handleBranch = (branchNum, lang) => {
    console.log('Change branch:', branchNum);
    if (branchNum === '00000') {
      setShowBranchNumber(false);
      setBranch('00000');
      setBranchNumber('00000');

      if (lang === 'en') {
        console.log('Set brach text English!');
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      if (branchNum !== '') {
        setBranch('branchNumber');
        if (branchNum === 'branchNumber') {
          setBranchNumber('00000');

          if (lang === 'en') {
            setBranchText('Branch 00000');
          } else {
            setBranchText('สาขา 00000');
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === 'en') {
            setBranchText('Branch ' + branchNum);
          } else {
            setBranchText('สาขา ' + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }

    if (lang === 'en') {
      setBranchDisplay([
        { key: '00000', text: 'Head office' },
        { key: 'branchNumber', text: 'Branch' },
      ]);
    } else {
      setBranchDisplay([
        { key: '00000', text: 'สำนักงานใหญ่' },
        { key: 'branchNumber', text: 'สาขาเลขที่' },
      ]);
    }
  };

  const getErrorMessage = (value) => {
    return value && value !== '0' && value.trim() ? '' : t('company.getErrorMessage');
  };

  const getErrorMessageTaxId = (value) => {
    return value && value.length === 13 ? '' : t('company.taxIdError');
  };

  const getBranchNumberErrorMessage = (value) => {
    if (!value) return t('company.getErrorMessage');
    if (isNaN(value)) return t('company.getBranchNumberErrorMessage1');
    if (value.length !== 5) return t('company.getBranchNumberErrorMessage2');
    return '';
  };

  // const getPhoneNumberErrorMessage = (value) => {
  //   if (phone && !phoneRegEx.test(phone)) {
  //     setPhoneNumberError(true);
  //     return t('company.getPhoneNumberErrorMessage');
  //   } else if (value === '' || value === null || value === undefined) {
  //     setPhoneNumberError(false);
  //     return t('company.getErrorMessage');
  //   } else {
  //     setPhoneNumberError(false);
  //     return '';
  //   }
  // };

  const getPhoneNumberErrorMessage = (value) => {
    setPhoneNumberError(false);
    if (value === null || value === undefined) {
      setPhoneNumberError(true);
      return t('company.getErrorMessage');
    } else if (!phoneRegEx.test(value)) {
      setPhoneNumberError(true);
      return t('company.getPhoneNumberErrorMessage');
    }
  };

  return (
    <Stack style={{ background: '#FAF9F8' }}>
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('company.updateSuccess')}
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('company.updateFail')}
        </MessageBar>
      )}
      {showMessageBarSearchError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('company.searchFail')}
        </MessageBar>
      )}
      {showMessageBarSearchNotFound && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchNotFound(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('company.searchNotFound')}
        </MessageBar>
      )}
      <form onSubmit={handleSubmit}>
        <Stack horizontal horizontalAlign="space-between">
          <h2 style={{ marginLeft: '0', marginTop: '27px' }}>
            <Icon iconName="CityNext2" style={{ color: '#106ebe' }} /> {t('company.header')}
          </h2>
          <Toggle
            styles={{ root: { marginTop: 17 } }}
            inlineLabel
            label={t('company.english')}
            onText={t('company.on')}
            offText={t('company.off')}
            checked={language === 'en' ? true : false}
            onChange={() => {
              if (language === 'en') {
                setLanguage('th');
              } else {
                setLanguage('en');
              }
            }}
          />
        </Stack>
        <div style={{ marginLeft: '0' }}>{t('company.headerDescription')}</div>

        <div>
          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>{t('company.companyInfo')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                <TextField
                  componentRef={(input) => (taxIdRef = input)}
                  name="taxId"
                  label={t('company.taxId')}
                  styles={{ root: { width: 220 } }}
                  maxLength={13}
                  value={taxId}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      console.log('Tax id lenght: ', e.target.value);
                      setTaxId(e.target.value);
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.trim()) {
                      setTaxId('');
                    } else {
                      setTaxId(e.target.value.trim());
                    }
                  }}
                  onGetErrorMessage={getErrorMessageTaxId}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                  disabled={companyDetailData?.billings && companyDetailData?.billings?.type === 'subscription'}
                />
                <Dropdown
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.branchDisplay')}
                  options={branchDisplay}
                  selectedKey={branch}
                  styles={{ root: { width: 160 } }}
                  onChange={(e, selectedOption) => handleBranch(selectedOption.key, language)}
                  required
                  disabled={!registerVat || (companyDetailData?.billings && companyDetailData?.billings?.type === 'subscription')}
                />

                {showBranchNumber && (
                  <TextField
                    componentRef={(input) => (branchNumberRef = input)}
                    name="branchNumber"
                    label={t('company.branchNumber')}
                    styles={{ root: { width: 100 } }}
                    value={branchNumber}
                    required={showBranchNumber}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setBranchNumber(e.target.value);

                        if (language === 'en') {
                          setBranchText('Branch ' + e.target.value);
                        } else {
                          setBranchText('สาขา ' + e.target.value);
                        }
                      }
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setBranchNumber('');
                      } else {
                        setBranchNumber(e.target.value.trim());

                        if (language === 'en') {
                          setBranchText('Branch ' + e.target.value.trim());
                        } else {
                          setBranchText('สาขา ' + e.target.value.trim());
                        }
                      }
                    }}
                    onGetErrorMessage={getBranchNumberErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    description={t('company.branchNumberDesc')}
                    disabled={companyDetailData?.billings && companyDetailData?.billings?.type === 'subscription'}
                  />
                )}
                <PrimaryButton
                  styles={{ root: { marginTop: 28.4 } }}
                  text={t('company.search')}
                  disabled={!isSearchCompleate}
                  iconProps={{ iconName: 'Search' }}
                  onClick={() => handleSearch()}
                />
              </Stack>

              {isSearchCompleate ? (
                <Stack horizontal tokens={stackTokens}>
                  <TextField
                    name="name"
                    label={t('company.name')}
                    styles={{ root: { width: 610 } }}
                    maxLength={140}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    // placeholder={t('company.namePlaceHolder')}
                    required
                  />
                </Stack>
              ) : (
                <Stack styles={{ root: { marginTop: 28.4 } }}>
                  <Shimmer shimmerElements={shimmerRowHeight32} width="610px" />
                </Stack>
              )}
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('company.companyInfo')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 38.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="220px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="160px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="100px" />
              </Stack>
              <Stack styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="610px" />
              </Stack>
            </div>
          )}

          <br />
          {isLoadComplete && isSearchCompleate ? (
            <div style={cardStyle}>
              <strong>{t('company.addressForCreate')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                <TextField
                  name="buildingNumber"
                  label={t('company.buildingNumber')}
                  styles={{ root: { width: 150 } }}
                  maxLength={16}
                  value={buildingNumber}
                  onChange={(e) => setBuildingNumber(e.target.value)}
                  onGetErrorMessage={getErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <TextField
                  name="address"
                  label={t('company.address')}
                  styles={{ root: { width: 450 } }}
                  maxLength={256}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="streetPrefix"
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.streetPrefix')}
                  options={[
                    { key: 'ถ.', text: 'ถ.' },
                    { key: 'ถนน', text: 'ถนน' },
                    { key: '', text: t('company.notDisplay') },
                  ]}
                  selectedKey={streetPrefix}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    setStreetPrefix(selectedOption.key);
                  }}
                />
                <TextField
                  name="streetName"
                  label={t('company.streetName')}
                  styles={{ root: { width: 450 } }}
                  maxLength={70}
                  value={streetName}
                  onChange={(e) => setStreetName(e.target.value)}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="provincePrefix"
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.provincePrefix')}
                  options={[
                    { key: 'จ.', text: 'จ.' },
                    { key: 'จังหวัด', text: 'จังหวัด' },
                    { key: '', text: t('company.notDisplay') },
                  ]}
                  selectedKey={provincePrefix}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    setProvincePrefix(selectedOption.key);
                  }}
                />
                <Dropdown
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.province')}
                  options={provinceList}
                  selectedKey={province.key}
                  styles={dropdownStyles}
                  onChange={(e, selectedOption) => {
                    console.log(selectedOption);
                    setProvince(selectedOption);
                    setDistrict({ key: '', text: '' });
                    setDistrictList(getDistrictList(selectedOption.key, language));
                    setSubDistrict({ key: '', text: '' });
                    console.log('Province changed', districtList);

                    setProvinceErrMsg('');
                    setDistrictErrMsg('');
                    setSubDistrictErrMsg('');
                  }}
                  onBlur={(e) => {
                    console.log(province.key);
                    if (province.key === '') {
                      setProvinceErrMsg(DropdownErrMsg);
                    } else {
                      setProvinceErrMsg('');
                    }
                  }}
                  errorMessage={provinceErrMsg}
                  required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="districtPrefix"
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.districtPrefix')}
                  options={[
                    { key: 'อ.', text: 'อ.' },
                    { key: 'อำเภอ', text: 'อำเภอ' },
                    { key: 'เขต', text: 'เขต' },
                    { key: '', text: t('company.notDisplay') },
                  ]}
                  selectedKey={districtPrefix}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    setDistrictPrefix(selectedOption.key);
                  }}
                />
                <Dropdown
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.district')}
                  options={districtList}
                  selectedKey={district.key}
                  styles={dropdownStyles}
                  onChange={(e, selectedOption) => {
                    setDistrict(selectedOption);
                    setSubDistrict({ key: '', text: '' });
                    setSubDistrictList(getSubDistrictList(selectedOption.key, language));
                    console.log('District changed', subDistrictList);

                    setDistrictErrMsg('');
                    setSubDistrictErrMsg('');
                  }}
                  onBlur={() => {
                    console.log(district.key);
                    if (province.key !== '' && district.key === '') {
                      setDistrictErrMsg(DropdownErrMsg);
                    } else {
                      setDistrictErrMsg('');
                    }
                  }}
                  errorMessage={districtErrMsg}
                  required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="subDistrictPrefix"
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.subDistrictPrefix')}
                  options={[
                    { key: 'ต.', text: 'ต.' },
                    { key: 'ตำบล', text: 'ตำบล' },
                    { key: 'แขวง', text: 'แขวง' },
                    { key: '', text: t('company.notDisplay') },
                  ]}
                  selectedKey={subDistrictPrefix}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    setSubDistrictPrefix(selectedOption.key);
                  }}
                />
                <Dropdown
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.subDistrict')}
                  options={subDistrictList}
                  selectedKey={subDistrict.key}
                  styles={dropdownStyles}
                  onChange={(e, selectedOption) => {
                    setSubDistrict(selectedOption);

                    setSubDistrictErrMsg('');
                  }}
                  onBlur={() => {
                    console.log(subDistrict.key);
                    if (district.key !== '' && subDistrict.key === '') {
                      setSubDistrictErrMsg(DropdownErrMsg);
                    } else {
                      setSubDistrictErrMsg('');
                    }
                  }}
                  errorMessage={subDistrictErrMsg}
                  required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <TextField
                  name="postcode"
                  label={t('company.postcode')}
                  styles={{ root: { width: 150 } }}
                  maxLength={16}
                  value={postcode}
                  onChange={(e) => {
                    if (!e.target.value.includes(' ') && !isNaN(e.target.value)) {
                      setPostcode(e.target.value);
                    }
                  }}
                  onGetErrorMessage={() => {
                    if (postcode.length !== 5) {
                      return t('company.getPostCodeErrorMessage2');
                    } else {
                      getErrorMessage();
                    }
                  }}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('company.addressForCreate')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 38.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              </Stack>
            </div>
          )}

          <br />
          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>{t('company.contactForCustomer')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 10 } }}>
                <TextField
                  name="phone"
                  label={t('company.phone')}
                  styles={{ root: { width: 300 } }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onGetErrorMessage={getPhoneNumberErrorMessage}
                  maxLength={35}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <TextField
                  name="email"
                  label={t('company.email')}
                  styles={{ root: { width: 300 } }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onGetErrorMessage={getErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>{t('company.contactForCustomer')}</strong>
              <Stack horizontal tokens={stackTokens} styles={{ root: { marginTop: 38.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </Stack>
            </div>
          )}
          <br />
          {isLoadComplete && isSearchCompleate ? (
            <div style={cardStyle}>
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Dropdown
                  placeholder={t('company.pleaseSelect')}
                  label={t('company.registerVat')}
                  options={[
                    { key: true, text: t('company.registerVatYes') },
                    { key: false, text: t('company.registerVatNo') },
                  ]}
                  selectedKey={registerVat}
                  styles={{ root: { width: 220 } }}
                  onChange={(e, selectedOption) => {
                    setRegisterVat(selectedOption.key);
                    handleBranch('00000', language);
                  }}
                  required
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="220px" />
              </Stack>
            </div>
          )}
          <br />
          <br />
          <Separator>{t('company.saveSeparator')}</Separator>
          <br />
          <br />

          <div align="center">
            {!buttonClick && (
              location.state?.command === "ADD" ? (
                <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                  <PrimaryButton text={t('company.add')} iconProps={{ iconName: 'Add' }} type="submit" />
                  <DefaultButton text={t('company.back')} onClick={handleCancel} />
                </Stack>
              ) : 
                <PrimaryButton name="save" text={t('company.save')} iconProps={{ iconName: 'Save' }} type="submit" />
            )}

            {buttonClick && <Spinner label={t('company.saving')} labelPosition="right" />}
          </div>
          <br />
          <br />
        </div>

        <br />
        <br />
      </form>
    </Stack>
  );
};

export default Companies;
