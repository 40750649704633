import React, { useState, useEffect } from "react";

import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles/style.css";
import "./ThemeSelector.css"; // Import CSS module
import { useTranslation } from "react-i18next";
import { themeQR } from "./ThemeColorsSetting.js";
import qrCode from "qrcode";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import {
  Button,
  Text,
  makeStyles,
  shorthands,
  Field,
  Textarea,
  Switch,
  typographyStyles,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  CardPreview,
  Spinner,
  Skeleton,
  SkeletonItem,
  MenuButton,
} from "@fluentui/react-components";
import { DocumentCopyRegular } from "@fluentui/react-icons";
import PdfAllPages from "./PdfAllPages.js";
import ThemeSelectorForQrCode from "./ThemeSelectorForQrCode.js";
const API_URL_FORM = process.env.REACT_APP_REQUEST_FORM_API_URL;
const WEB_APP_URL = process.env.REACT_APP_REQUEST_FORM_URL;

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  title: typographyStyles.title2,
  languagechange: typographyStyles.subtitle2,
  labeldata: typographyStyles.body1Stronger,
  variabledata: typographyStyles.body1,
  captiondata: typographyStyles.title3,
  emaildata: typographyStyles.title3,

  firstshade: "var(--firstshade-color)",
  firstRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: "10px",
    gridTemplateColumns: "min-content 80%",
  },

  Paper: {
    maxWidth: "400px",
    height: "500px",
    backgroundColor: "red",
  },
});

const AdminManagementQrCode = ({
  EditConfirm,
  EditUnsuccess,
  CopySuccess,
  setIsLoadComplete,
  formId,
  setFormId,
  isEnabled,
  setIsEnabled,
  setSavedData,
  themeLoading,
  setThemeLoading,
  logoLoading,
  setLogoLoading,
  urlId,
  seturlId,
  logoIsHere,
  setLogoIsHere,
  logoIsHereForCompare,
  setLogoIsHereForCompare,
  setPolicyMessage,
  inProgress,
  setDismissTimeout
}) => {

  const styles = useStyles();
  const qrSize = 250;
  const [logo, setLogo] = useState(null);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [topicState, setTopicState] = useState("แบบฟอร์มขอใบกำกับภาษี");
  const [scanQRState, setScanQRState] = useState("สแกน QR CODE");
  const [editorState, setEditorState] = useState("");
  const [editorStateFromServer, setEditorStateFromServer] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const { t } = useTranslation();
  const UrlApp = `${WEB_APP_URL}/${urlId}`;
  const companyId = sessionStorage.getItem("companyId") || "";



  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(UrlApp);

      CopySuccess();
    } catch (err) {
      console.log(err);
    }
  };

  const getFormId = async () => {
    setThemeLoading(true);
    setLogoLoading(true);
    setIsLoadComplete(false);

    try {
       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }
          
      const response = await axios.get(`${API_URL_FORM}/forms/`, {
        headers: {
          "cid": `${companyId}`,
          "Authorization": "Bearer " + tokenResponse.accessToken,

        },
      });

      setFormId(response.data.id);

      seturlId(response.data.urlName);
      setSavedData(response.data.message);
      setPolicyMessage(response.data.privacyPolicyMessage)

      if (isEnabled === null) {
        setIsEnabled(response.data.enabled);
      }
    } catch (error) {
      console.error("Error fetching form ID:", error);
      if (error.response.data.statusCode === 404) {
        console.log("CreatesFormsByCid");
        CreatesFormsByCid();
      }
    } finally {
      setIsLoadComplete(true);
    }
  };
  useEffect(() => {
    getFormId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreatesFormsByCid = async () => {
    try {
       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });

          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

    
      
      const response = await axios.post(`${API_URL_FORM}/Forms/`,{}, {
        headers: {
          "cid": `${companyId}`,
          "Authorization": "Bearer " + tokenResponse.accessToken,
          "oid": tokenResponse.idTokenClaims.oid
        },
      });

      console.log("response", response);
      if (response.data.statusCode === 200) {
        console.log("create", response.data.message);
        getFormId();
      }
    } catch (error) {
      console.error("Error fetching form ID:", error);
    }
  };

  const opts = {
    errorCorrectionLevel: "H",
    type: "image/jpeg",
    quality: 0.3,
    margin: 1,
    // color: {
    //   dark:"#010599FF",
    //   light:"#FFBF60FF"
    // }
  };

  const handleQr = (webAppUrl) => {
    qrCode
      .toDataURL(webAppUrl, opts)
      .then((url) => {
        console.log(url);
        setQrCodeUrl(url);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (urlId !== ""){
        handleQr(UrlApp);
    }
  

    // eslint-disable-next-line
  }, [urlId]);
  
       useEffect(()=>{
        const logotest = null
        const logotestCurrentDatabase = null

        const logoStatus = logotest === logotestCurrentDatabase 
        console.log("logo status",logoStatus);
       },[])
        

  const [nameFileReady, setNameFileReady] = useState(null);

  const handleFileUpload = async (file, realfile) => {
    if (file) {
      try {
        // setIsLoadComplete(false);
       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }
        const formData = new FormData();
        formData.append("CompanyLogo", realfile);

        // Second HTTP request
        // const responsefromStorage = await axios.post(
        //   "http://localhost:5004/api/LogoUpload",
        //   formData,
        const responsefromStorage = await axios.post(
          `${API_URL_FORM}/logos/`,
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              "fid": formId,
              "Authorization": "Bearer " + tokenResponse.accessToken,
            },
          }
        );


        if (
          responsefromStorage.status >= 200 &&
          responsefromStorage.status < 300
        ) {
          if (fileCompanyImage === null && realImageFile === null) {
            setLogo(null);

            const fileName = null;
            handleNameUpload(fileCompanyImage, fileName);
            setLogoIsHere(false)
            setLogoIsHereForCompare(false)
          } else {
            setNameFileReady(responsefromStorage.data.fileName);
            setLogoIsHere(true)
            setLogoIsHereForCompare(true)
          }

        } else {
          console.error(
            "Upload RealFile failed with status:",
            responsefromStorage.status,
            responsefromStorage.statusText
          );
        }

      } catch (error) {
        if (error.response) {
          console.error(
            "Error response from server:",
            error.response.status,
            error.response.statusText,
            error.response.data
          );
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      } finally {
        setIsLoadComplete(true);
      }
    }
  };

  const handleNameUpload = async (file, namefile) => {
    try {
      setThemeLoading(true);
      setLogoLoading(true);

      const patchPayload = {
          replace: "/companyLogo",
          value: namefile,
        };
      

      // First HTTP request
      // const response = await axios.patch(
      //   `http://localhost:5004/api/forms/${formId}`,
      //   patchPayload
      // );

       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

      const response = await axios.patch(
        `${API_URL_FORM}/forms/${formId}`,
        patchPayload,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            // "fid": formId,
            "Authorization": "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Upload real image success", response.data);
        console.log("Upload successful:", response.data);

        setThemeLoading(false);
        setLogoLoading(false);
        EditConfirm();
      } else {
        console.error(
          "Upload failed with status:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Error response from server:",
          error.response.status,
          error.response.statusText,
          error.response.data
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    } finally {
      // setThemeLoading(false);
      // setLogoLoading(false);
    }
  };

  useEffect(() => {
    if (nameFileReady !== null) {
      handleNameUpload(fileCompanyImage, nameFileReady);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFileReady]);

  const [fileCompanyImage, setFileCompanyImage] = useState(null);
  const [realImageFile, setRealImageFile] = useState(null);
  const handleFileChange = async (e) => {
    setThemeLoading(true);
    setLogoLoading(true);
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = async () => {
      const base64String = reader.result; // Use the entire reader.result as the Base64 string
      setLogo(base64String); // Set the Base64 string as the logo in state
      setFileCompanyImage(base64String);
      setLogoIsHere(true)
      setLogoIsHereForCompare(false)
      setThemeLoading(false);
      setLogoLoading(false);
    };
    reader.readAsDataURL(file); // Read the file as a data URL

    setRealImageFile(file);

    // handleFileUpload(fileCompanyImage);
  };

  const handleRemoveImage = async () => {
    try {
      // setIsLoadComplete(false);
      setLogo(null);
    
      // const response = await axios.patch(
      //   `http://localhost:5004/api/forms/${formId}`,

      //   {
      //     "replace": "/companyLogo",
      //     "value": "null",
      //   }
      // );

       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

      const response = await axios.patch(
        `${API_URL_FORM}/forms/${formId}`,

        {
          "replace": "/companyLogo",
          "value": null,
        },
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      // Check the response status
      if (response.status >= 200 && response.status < 300) {
        console.log("Deleted companyLogo successful:", response.data);

        setFileCompanyImage(null);
        setLogo(null);

        setLogoIsHereForCompare(false)

      } else {
        console.error(
          "Deleted failed with status:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          "Error response from server:",
          error.response.status,
          error.response.statusText,
          error.response.data
        );
      } else if (error.request) {
        // Request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
    } finally {
      // setIsLoadComplete(true);
    }
  };

  const [responseExampleFromServer, setResponseExampleFromServer] =
    useState(null);

  async function generatePDF(imageUrl, text, logo, selectedPalette) {
    try {
      setLoading(true);
      //http://localhost:5004/api/forms/pdf
      //${API_URL}/forms/pdf

       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

      const response = await axios.post(
        `${API_URL_FORM}/forms/pdf`,
        {
          imageUrl,
          text,
          logo,
          qrSize,
          selectedPalette,
          etaxLogoAlready,
          topicState,
          scanQRState,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            "fid": formId,
             Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      console.log("response data", response);
      // Return the PDF blob
      setResponseExampleFromServer(window.URL.createObjectURL(response.data));
    } catch (error) {
      console.error("Error generating PDF:", error);
      // Handle error
    } finally {
      setLoading(false);
    }
  }

  const getPDFpaper = (QRCodePIC) => {
    generatePDF(QRCodePIC, editorState, logo, selectedPalette, etaxLogoAlready);
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const [etaxLogoAlready, setEtaxLogoAlready] = useState(true);
  const [etaxLogoAlreadyFromDatabase, setEtaxLogoAlreadyFromDatabase] =
    useState(true);

  const [languagePaper, setLanguagePaper] = useState("");
  const [languagePaperFromDatabase, setlanguagePaperFromDatabase] =
    useState("");

  const CloseEtaxLogo = () => {
    setEtaxLogoAlready((prevState) => !prevState);
  };

  const ChangePaperLanguage = () => {
    if (languagePaper === "th") {
      setLanguagePaper("en");
    } else if (languagePaper === "en") {
      setLanguagePaper("th");
    }
  };

  const setPaperLanguageText = () => {
    if (languagePaper === "th") {
      setTopicState("แบบฟอร์มขอใบกำกับภาษี");
      setScanQRState("สแกน QR CODE");
    } else {
      setTopicState("Request Tax Invoice Form");
      setScanQRState("SCAN QR CODE");
    }
  };

  useEffect(() => {
    setPaperLanguageText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagePaper]);

  const [loading, setLoading] = useState(true);

  const handlePrint = () => {
    const newWindow = window.open(responseExampleFromServer, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      // Popup blocked, inform user to allow pop-ups
      alert("Please allow pop-ups for this website to print the PDF.");
    }
  };

  // const savedPalette = localStorage.getItem("selectedBackground");
  const defaultPalette = {
    "id": "001",
    "name": "มาตรฐาน",
    "firstshade": "#3174C2",
    "secondshade": "#3174c2",
    "thirdshade": "#c6d6e7",
    "fourthshade": "#c6d6e7",
  };
  const initialPalette = {
    "id": "001",
    "name": "มาตรฐาน",
    "firstshade": "#3174C2",
    "secondshade": "#3174c2",
    "thirdshade": "#c6d6e7",
    "fourthshade": "#c6d6e7",
  };

  const [selectedPalette, setSelectedPalette] = useState(initialPalette);

  const palettesQR = [
    {
      "id": "1",
      "name": "มาตรฐาน",
      "firstshade": "#3174C2",
      "secondshade": "#3174c2",
      "thirdshade": "#c6d6e7",
      "fourthshade": "#c6d6e7",
    },
  ];



  const themeGetter = (id) => {
    return themeQR.find((theme) => theme.id === id);
  };

  const fetchLoadResource = async () => {
    setThemeLoading(true);
    setLogoLoading(true);
    try {
      // const response = await axios.get(
      //   `http://localhost:5004/api/forms/${formId}`
      // );
       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

      const response = await axios.get(`${API_URL_FORM}/forms/${formId}`, {
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
        },
      });

      if (response.data.companyLogo === null) {
        setLogo(null);
        setLogoIsHere(false)
        setLogoIsHereForCompare(false)
        // setThemeLoading(false);
        // setLogoLoading(false);
      } else {
        // setLogo(response.data.companyLogo);


        // const fid = formId;
        // await handleDownloadImage(response.data.companyLogo, fid);


        const response = await axios.get(`${API_URL_FORM}/forms/${formId}`, {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        });

        const responseImage = await axios.get(`${API_URL_FORM}/logos/`, {
          responseType: "blob", // Specify response type as blob
          headers: {
            "fid": formId,
            "filename": response.data.companyLogo,
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        });

        if (responseImage.data) {
          const reader = new FileReader();
          reader.readAsDataURL(responseImage.data); // Read blob data as data URL

          reader.onload = () => {
            const base64String = reader.result;


            setLogo(base64String);

            setLogoIsHere(true)
            setLogoIsHereForCompare(true)
          };
        }
      }

      // setPalletesQR(response.data.themeQR);

      if (response.data.setting.qrCode.etaxLogo === true) {
        setEtaxLogoAlready(true);
        setEtaxLogoAlreadyFromDatabase(true);
      } else {
        setEtaxLogoAlready(false);
        setEtaxLogoAlreadyFromDatabase(false);
      }

      if (response.data.setting.qrCode.language === "th") {
        setLanguagePaper("th");
        setlanguagePaperFromDatabase("th");
      } else {
        setLanguagePaper("en");
        setlanguagePaperFromDatabase("en");
      }

      const themecurrent = themeGetter(response.data.setting.qrCode.themeId);
      console.log("theme current", themecurrent);
      applyPalette(response.data.setting.qrCode.themeId, themecurrent);
      applyPaletteFromServer(
        response.data.setting.qrCode.themeId,
        themecurrent
      );
      setEditorState(response.data.setting.qrCode.description);
      setEditorStateFromServer(response.data.setting.qrCode.description);

      if (response.data) {
        setThemeLoading(false);
        setLogoLoading(false);
        setIsLoadComplete(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      instance.acquireTokenRedirect(silentRequest);
    } finally {
      // setThemeLoading(false);
      // setLogoLoading(false);
    }
  };

  useEffect(() => {
    if (formId) {
      fetchLoadResource();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  // const handleDownloadImage = async (fileName) => {
  //   try {

  //     // const response = await axios.get(
  //     //   "http://localhost:5004/api/LogoDownLoad",
  //     //   {
  //     //     responseType: "blob", // Specify response type as blob
  //     //     headers: {
  //     //       "fid": formId,
  //     //       "filename": fileName,
  //     //     },
  //     //   }
  //     // );

  //     const response = await axios.get(`${API_URL}/logos/`, {
  //       responseType: "blob", // Specify response type as blob
  //       headers: {
  //         "fid": formId,
  //         "filename": fileName,
  //       },
  //     });

  //     if (response.data) {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(response.data); // Read blob data as data URL

  //       reader.onload = () => {
  //         const base64String = reader.result;


  //         setLogo(base64String);

  //       };
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const [setPaperSize, paperSize] = useState("A5");

  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedThemeFromDatabase, setSelectedThemeFromDatabase] =
    useState(null);
  // Function to apply selected palette
  const applyPalette = (themeId, palette) => {
    const { firstshade, secondshade, thirdshade, fourthshade } = palette;

    setSelectedTheme(themeId);

    document.documentElement.style.setProperty(
      "--firstshade-color",
      firstshade
    );
    document.documentElement.style.setProperty(
      "--secondshade-color",
      secondshade
    );
    document.documentElement.style.setProperty(
      "--thirdshade-color",
      thirdshade
    );
    document.documentElement.style.setProperty(
      "--fourthshade-color",
      fourthshade
    );

    setSelectedPalette(palette);

    // localStorage.setItem("selectedBackground", JSON.stringify(palette));
  };

  const applyPaletteFromServer = (themeId, palette) => {
    // const { id } = palette;

    setSelectedThemeFromDatabase(themeId);
  };

  useEffect(() => {
    applyPalette("001", initialPalette);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once when the component mounts

  const handleSaveData = async () => {
    try {
      setThemeLoading(true);
      setLogoLoading(true);
      inProgress()
      setDismissTimeout(40000)
      // const response = await axios.patch(
      //   `http://localhost:5004/api/forms/${formId}`,
      //   {
      //     // Ensure the request payload is structured correctly
      //     "replace": "/qrCodeStuff",
      //     "value": {
      //       "themepaper": selectedPalette,
      //       "description": editorState,
      //       "etaxLogoAlready": etaxLogoAlready,
      //       "paperlanguage": thaipaper,
      //     },
      //   }
      // );
      //`${API_URL}/forms/${formId}`
       let tokenResponse;
          try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
          } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
          }

      const response = await axios.patch(
        `${API_URL_FORM}/forms/${formId}`,
        {
          // Ensure the request payload is structured correctly
          "replace": "/qrCode",
          "value": {
            "themeId": selectedPalette.id,
            "description": editorState,
            "etaxLogo": etaxLogoAlready,
            "language": languagePaper,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      // const responseThemeQR = await axios.patch(`${API_URL}/forms/${formId}`, {
      //   // Ensure the request payload is structured correctly
      //   "replace": "/settingThemeQrId",
      //   "value": selectedPalette.id,
      // });

      if (response.status === 200) {
        setEtaxLogoAlreadyFromDatabase(etaxLogoAlready);
        setSelectedThemeFromDatabase(selectedTheme);
        setEditorStateFromServer(editorState);

        setlanguagePaperFromDatabase(languagePaper);
        setDismissTimeout(0)
        EditConfirm();
        // setThemeLoading(false);
        // setLogoLoading(false);


     
        // Optionally, you can reset the state or show a success message to the user
      } else {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      EditUnsuccess();
      setDismissTimeout(0)
      // Handle error: show error message to the user or retry saving
    } finally {
    if (fileCompanyImage !== null) {
      handleFileUpload(fileCompanyImage, realImageFile);
      }else{
      setThemeLoading(false);
      setLogoLoading(false);
      setLogoIsHereForCompare(true)
      // setLogoIsHereForCompare(false)
      // EditConfirm();
      setDismissTimeout(0)
    }
    }
  };

  const handleResetData = async () => {
    setThemeLoading(true);
    setLogoLoading(true);
    inProgress()
    setDismissTimeout(40000)
    try {
      setEditorState("");
      setEditorStateFromServer("");
      setEtaxLogoAlready(true);
      setEtaxLogoAlreadyFromDatabase(true);
      setLogo(null);
      setLanguagePaper("th");
      setlanguagePaperFromDatabase("th");
      applyPalette("001", defaultPalette);
      applyPaletteFromServer("001", defaultPalette);
      setFileCompanyImage(null);
      setRealImageFile(null);
      setNameFileReady(null);
      handleRemoveImage();
    
       let tokenResponse;
        try {
            tokenResponse = await instance.acquireTokenSilent({
            ...silentRequest,
            account: account,
            });
        } catch (error) {
            console.error("Error acquiring token: ", error);
            instance.acquireTokenRedirect(silentRequest);
            return; 
        }

      const response = await axios.patch(
        `${API_URL_FORM}/forms/${formId}`,
        {
          // Ensure the request payload is structured correctly
          "replace": "/qrCode",
          "value": {
            "themeId": "001",
            "description": "",
            "etaxLogo": true,
            "language": "th",
          },
        },
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );

      if (response.status === 200) {
        setEtaxLogoAlreadyFromDatabase(true);
        
        setSelectedThemeFromDatabase(selectedTheme);
        setSelectedTheme(selectedTheme)
        setEditorState("");
        setEditorStateFromServer("");
        setLogoIsHere(false)
        setLogoIsHereForCompare(false)
        setLanguagePaper("th")
        setlanguagePaperFromDatabase("th");
        setThemeLoading(false);
        setLogoLoading(false);
        setDismissTimeout(0)
        EditConfirm();
        // Optionally, you can reset the state or show a success message to the user
      } else {
        setDismissTimeout(0)
        throw new Error("Failed to save data");
        
      }
    } catch (error) {
      console.error("Error Reset data:", error.message);
      setDismissTimeout(0)
      // Handle error: show error message to the user or retry saving
    }
  };

  return (
    <div className="qr-code-generator">
      <div className="left-side">
        <h2> {t("requestFormAdminFormEditor.editPaper")}</h2>
        <div>
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "21px",
              }}
            >
              <Text>{t("requestFormAdminFormEditor.youCanAddLogo")}</Text>
            </div>
            {logoLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "10px",
                  color: "#555",
                }}
              >
                <div>
                  <Skeleton>
                    <SkeletonItem className={styles.firstRow} size={20}>
                      <div style={{ display: "flex", width: "100px" }}></div>
                    </SkeletonItem>
                  </Skeleton>
                </div>
              </div>
            ) : (
              <>
                {logo && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px",
                      color: "#555",
                    }}
                  >
                    {/* <img
                    src={`data:image/png;base64=${logo}`}
                    alt="Logo"
                    style={{ height: "30px", marginBottom: "25px" }}
                  /> */}

                    <Dialog modalType="modal">
                      <DialogTrigger disableButtonEnhancement>
                        <Button>
                          {t("requestFormAdminFormEditor.deleteLogo")}
                        </Button>
                      </DialogTrigger>

                      <DialogSurface className="fluentui-dialog">
                        <DialogBody className="dialog-body">
                          <DialogTitle>
                            {" "}
                            {t("requestFormAdminFormEditor.confirmLogo")}
                          </DialogTitle>
                          <DialogContent>
                            {" "}
                            {t(
                              "requestFormAdminFormEditor.confirmLogoDeletion"
                            )}
                          </DialogContent>
                          <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                appearance="primary"
                                className="button-dialog"
                                onClick={handleRemoveImage}
                              >
                                {t("requestFormAdminFormEditor.confirm")}
                              </Button>
                            </DialogTrigger>

                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                appearance="secondary"
                                className="button-dialog"
                              >
                                {t("requestFormAdminFormEditor.cancel")}
                              </Button>
                            </DialogTrigger>
                          </DialogActions>
                        </DialogBody>
                      </DialogSurface>
                    </Dialog>
                  </div>
                )}
                {!logo && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "10px",
                        color: "#555",
                      }}
                    >
                      {/* <p
                      style={{
                        margin: "10px 0",
                        marginBottom: "25px",

                        fontSize: "15px",
                      }}
                    >
                      อัปโหลดโลโก้
                    </p> */}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="fileInput"
                      />
                      <Button
                        appearance="primary"
                        // disabled={!isChecked}
                        iconProps={{ iconName: "Document" }}
                        onClick={() => {
                          document.getElementById("fileInput").click();
                          
                        }}
                      >
                        {t("requestFormAdminFormEditor.uploadLogo")}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </>

          {logoLoading & themeLoading ? (
            <div style={{ marginBottom: "2px" }}>
              <Skeleton>
                <SkeletonItem className={styles.firstRow} size={12}>
                  <div
                    style={{ display: "flex", width: "100px", height: "0.5px" }}
                  ></div>
                </SkeletonItem>
              </Skeleton>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                <Switch
                  checked={etaxLogoAlready}
                  label={t("requestFormAdminFormEditor.toEnableOrDisable")}
                  onChange={() => CloseEtaxLogo()}
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <Text
                  style={{
                    marginLeft: "5px",

                    marginRight: "5px",

                    color: etaxLogoAlready ? "green" : "red",
                  }}
                >
                  {etaxLogoAlready
                    ? t("requestFormAdminFormEditor.avaliable")
                    : t("requestFormAdminFormEditor.disabled")}
                </Text>
              </div>
            </div>
          )}
          {logoLoading & themeLoading ? (
            <div>
              <Skeleton>
                <SkeletonItem className={styles.firstRow} size={12}>
                  <div
                    style={{ display: "flex", width: "100px", height: "0.5px" }}
                  ></div>
                </SkeletonItem>
              </Skeleton>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                <Switch
                  checked={languagePaper === "th"}
                  label={t("requestFormAdminFormEditor.paperLanguage")}
                  onChange={() => ChangePaperLanguage()}
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <Text
                  style={{
                    marginLeft: "5px",

                    marginRight: "5px",

                    color: "blue",
                  }}
                >
                  {languagePaper === "th"
                    ? t("requestFormAdminFormEditor.thaiPaper")
                    : t("requestFormAdminFormEditor.englishPaper")}
                </Text>
              </div>

              {languagePaper === "th" ? (
                <div
                  style={{
                    display: "flex",

                    justifyContent: "center",
                    alignContent: "center",

                    flexDirection: "column",
                  }}
                >
                  {" "}
                  {/* <ThailandFlag /> */}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",

                    justifyContent: "center",
                    alignContent: "center",

                    flexDirection: "column",
                  }}
                >
                  {" "}
                  {/* <EnglandFlag /> */}
                  {/* <EnglishFlag /> */}
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <ThemeSelectorForQrCode
            applyPalette={applyPalette}
            initialPalette={initialPalette}
            selectedTheme={selectedTheme}
            palettesQR={palettesQR}
            // savedPalette={savedPalette}

            themeLoading={themeLoading}
          />
        </div>
        <div className="wrap-paper" style={{ marginTop: "35px" }}>
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              height: "15px",
              fontWeight: "bold",
            }}
          >
            <Text> {t("requestFormAdminFormEditor.addDescription")}</Text>
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              marginBottom: "10px",
            }}
          >
            <Textarea
              className="editor-qr"
              value={editorState}
              onChange={(e) => handleEditorStateChange(e.target.value)}
              maxLength={34}
              disabled={logoLoading}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          position: "absolute",
          left: "0px",
          bottom: "35px",

          justifyContent: "center",

          justifyItems: "center",
        }}
      >
        <div
          style={{
            width: "400px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              justifyContent: "center",
              alignContent: "center",
              verticalAlign: "center",
            }}
          >
            <Dialog modalType="modal">
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="primary"
                  style={{ marginRight: "10px" }}
                  disabled={logoLoading}
                >
                  {t("requestFormAdminFormEditor.save")}
                </Button>
              </DialogTrigger>

              <DialogSurface className="fluentui-dialog">
                <DialogBody className="dialog-body">
                  <DialogTitle>
                    {" "}
                    {t("requestFormAdminFormEditor.confirmForSave")}
                  </DialogTitle>

                  <DialogContent>
                    {" "}
                    {t("requestFormAdminFormEditor.confirmToSave")}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        appearance="primary"
                        className="button-dialog"
                        onClick={() => {

                          // handleFileUpload(fileCompanyImage, realImageFile)
                          handleSaveData();
                        }}
                      >
                        {t("requestFormAdminFormEditor.confirm")}
                      </Button>
                    </DialogTrigger>

                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary" className="button-dialog">
                        {t("requestFormAdminFormEditor.cancel")}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>
        </div>

        <div
          style={{
            width: "400px",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "4%",
              marginRight: "30px",
              minWidth: "180px",
            }}
          >
            <Dialog>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => getPDFpaper(qrCodeUrl)}
                  disabled={
                    logoLoading ||
                    etaxLogoAlreadyFromDatabase !== etaxLogoAlready ||
                    selectedTheme !== selectedThemeFromDatabase ||
                    editorStateFromServer !== editorState ||
                    languagePaper !== languagePaperFromDatabase || logoIsHere !== logoIsHereForCompare
                  }
                >
                  {t("requestFormAdminFormEditor.previewBeforePrinting")}
                </Button>
              </DialogTrigger>
              <DialogSurface className="fluentui-dialogpolicy">
                <DialogBody className="dialog-bodypolicy">
                  <DialogTitle className={styles.languagechange}>
                    {t("requestFormAdminFormEditor.previewPaper")}
                  </DialogTitle>
                  <DialogContent
                    style={
                      !loading
                        ? {}
                        : {
                            overflowX: "hidden",
                            overflowY: "hidden",
                          }
                    }
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Spinner
                          label={t("requestFormAdminFormEditor.creatingPaper")}
                        />
                      </div>
                    ) : (
                      <CardPreview
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          justifyItems: "center",
                        }}
                      >
                        <PdfAllPages pdfBlobUrl={responseExampleFromServer} />
                      </CardPreview>
                    )}
                  </DialogContent>
                  {!loading ? (
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button
                          onClick={() => setResponseExampleFromServer(null)}
                          appearance="secondary"
                        >
                          {t("requestFormAdminFormEditor.cancel")}
                        </Button>
                      </DialogTrigger>

                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary" onClick={handlePrint}>
                          {t("requestFormAdminFormEditor.print")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  ) : (
                    ""
                  )}
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>

          {!logoLoading &&
            (
              etaxLogoAlreadyFromDatabase !== etaxLogoAlready ||
              selectedTheme !== selectedThemeFromDatabase ||
              editorStateFromServer !== editorState ||
              languagePaperFromDatabase !== languagePaper || logoIsHere !== logoIsHereForCompare) && (
              <div style={{ position: "absolute", top: "35px" }}>
                <Field
                  validationState="error"
                  validationMessage={
                    <Text> {t("requestFormAdminFormEditor.thereIsEdit")}</Text>
                  }
                >
                  {" "}
                </Field>
              </div>
            )}
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                justifyContent: "center",
                alignContent: "center",
                verticalAlign: "center",
                minWidth: "170px",
              }}
            >
              <Dialog modalType="modal">
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secendary"
                    disabled={logoLoading}
                    // style={{ marginRight: "50px", marginTop: "80px" }}
                  >
                    {t("requestFormAdminFormEditor.resetCodeFormat")}
                  </Button>
                </DialogTrigger>

                <DialogSurface className="fluentui-dialog">
                  <DialogBody className="dialog-body">
                    <DialogTitle>
                      {" "}
                      {t("requestFormAdminFormEditor.resetFormatQrCode")}
                    </DialogTitle>
                    <DialogContent>
                      {" "}
                      {t("requestFormAdminFormEditor.wouldYouLikeToReset")}
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button
                          appearance="primary"
                          className="button-dialog"
                          onClick={() => {
                            handleResetData();
                          }}
                        >
                          {t("requestFormAdminFormEditor.confirm")}
                        </Button>
                      </DialogTrigger>

                      <DialogTrigger disableButtonEnhancement>
                        <Button
                          appearance="secondary"
                          className="button-dialog"
                        >
                          {t("requestFormAdminFormEditor.cancel")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            </div>
          </div>

          {/* <div
            style={{
              position: "absolute",
              right: "-50px",
              zIndex: "999",
              backgroundColor: "red",
            }}
          >
        
            
          </div> */}
        </div>
      </div>

      <div className="right-side">
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: "-100",
          }}
        />

        <div
          style={{
            opacity: 1,
          }}
          className="font-preview"
        >
          <>
            <div style={{ display: "flex" }}>
              <h2 size="200" style={{ fontSize: "20px" }} fontWeight="450">
                {t("requestFormAdminFormEditor.displayPaper")}
              </h2>{" "}
            </div>

            {logoLoading && themeLoading ? (
              <div
                style={{
                  maxWidth: "400px",
                  display: "flex",
                  marginBottom: "5px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Skeleton>
                  <SkeletonItem
                    style={{
                      maxWidth: "380px",
                      paddingTop: "10px",
                      paddingBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "380px",
                        display: "flex",
                        paddingTop: "10px",
                        paddingBottom: "5px",
                      }}
                    ></div>
                  </SkeletonItem>
                </Skeleton>
              </div>
            ) : (
              <div
                style={{
                  maxWidth: "400px",
                  display: "flex",
                  marginBottom: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div style={{ alignItems: "center", width: "86%" }}>
                  <input
                    type="text"
                    readOnly
                    value={UrlApp}
                    style={{
                      padding: 8,
                      border: "1px solid #ccc",
                      alignContent: "center",
                      width: "100%",
                    }}
                  ></input>
                </div>
                <div
                  style={{
                    width: "14%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <MenuButton
                      icon={<DocumentCopyRegular />}
                      onClick={copyToClipboard}
                      style={{
                        border: "1px solid #ccc",
                        alignItems: "end",
                        height: "34px",
                        zIndex: "1",
                      }}
                      // style={{ minWidth: 50, padding: '0 12px',height:"35px" }}
                    ></MenuButton>
                  </div>
                </div>
              </div>
            )}

            {logoLoading && themeLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "10px",
                  color: "#555",
                  maxWidth: "400px",
                }}
              >
                <div>
                  <div>
                    <Skeleton>
                      <SkeletonItem
                        style={{
                          maxWidth: "380px",
                          height: "515px",
                        }}
                      >
                        <div
                          style={{
                            width: "400px",
                            height: "400px",
                          }}
                        ></div>
                      </SkeletonItem>
                    </Skeleton>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <div>
                  <div className="style-paper-only">
                    <div
                      style={{
                        width: "380px",
                        height: "13%",
                        position: "relative",
                      }}
                    >
                      {etaxLogoAlready && (
                        <div
                          style={{
                            position: "absolute",
                            left: "-5%",
                            top: "-15px",
                            verticalAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            <img
                              src="/images/etaxlogo.png"
                              alt="Logo"
                              style={{
                                width: "110px",
                                marginTop: "10px",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex", height: "6%" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: "80%",
                            height: "50%",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "24px",
                              color: "rgb(0, 0, 0)",
                              fontWeight: "bolder",
                            }}
                          >
                            {topicState}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "67%",
                        padding: "5%",
                      }}
                    >
                      <div
                        style={{
                          width: "90%",
                          height: "120%",
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          zIndex: 1,
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        >
                          {/* Header */}
                          {logo && (
                            <div
                              style={{
                                width: "100%",
                                height: "20%",
                                color: "black",
                                fontWeight: "bolder",

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                verticalAlign: "center",
                                backgroundColor: "var(--firstshade-color)",
                                marginBottom: "5%",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  verticalAlign: "center",
                                }}
                              >
                                <img
                                  src={`data:image/png;base64=${logo}`}
                                  alt="Logo"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>
                          )}

                          {/* QR Code Container */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "rgb(255, 255, 255)",

                              height: "80%",
                              width: "80%",
                              margin: "auto",
                              marginBottom: "2%",
                            }}
                          >
                            {/* <img src={imageUrl} alt="QR Code" width="100%" height="100%" style={{ paddingTop: '10%' }} /> */}
                            <div>
                              {/* <QRCode
                                // size={qrSize}
                                size={250}
                                bgColor={qrStyle.bgColor}
                                fgColor={`rgba(${qrColor.r}, ${qrColor.g}, ${qrColor.b}, ${qrColor.a})`}
                                level="H"
                                renderAs="svg"
                                value={url}
                              /> */}
                              <img
                                src={qrCodeUrl}
                                alt="QR Code"
                                width="250px"
                                height="250px"
                              ></img>

                              {/* <img src={qrCodeURL} alt="QR Code" style={{ width: qrSize, height: qrSize }} /> */}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              height: "10%",
                              color: "black",
                              fontSize: "28px",
                              fontWeight: "bolder",
                              borderRadius: "10px",
                              marginBottom: "3px",
                              paddingBottom: "1px",
                            }}
                          >
                            {scanQRState}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "var(--firstshade-color)",
                        padding: "12px",

                        height: "18%",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "var(--firstshade-color)",
                          width: "100%",
                          minHeight: "10%",

                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",

                            fontWeight: "bolder",
                            textAlign: "center",
                            color: "white",
                            fontSize: "20px",
                            width: "100%",
                          }}
                        >
                          {editorState ? (
                            <div style={{ marginTop: "11%" }}>
                              {editorState}
                            </div>
                          ) : (
                            <div style={{ marginTop: "11%" }}>
                              {t("requestFormAdminFormEditor.addingHere")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          right: "20%",
                          bottom: "0px",
                        }}
                      >
                        <div className="footer">
                          <div
                            style={{
                              position: "absolute",
                              right: "-70px",
                              bottom: "3px",
                              display: "flex",
                              width: "130px",
                              justifyContent: "center",
                              justifyItems: "center",
                              
                            }}
                          >
                            <div
                              style={{
                                fontSize: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                }}
                              >
                                Powered by
                              </label>
                            </div>
                            <div>
                              <img
                                src="/images/leceipt-logo-new.png"
                                alt="Logo"
                                style={{
                                  width:"50px",
                                  paddingBottom: "1px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default AdminManagementQrCode;
