import React, { useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { SearchBox } from "@fluentui/react-search-preview";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { DropDownForContactsList } from "./DropDownForContactsList";
import { DatePicker, Icon } from "@fluentui/react";
import { QrCodeFilled } from "@fluentui/react-icons";
import { MenuButton, Text } from "@fluentui/react-components";
import {
  Button,
  Checkbox,
  Toaster,
  useId,
  useToastController,
  Toast,
  ToastTitle,
  Link,
  ToastTrigger,
  makeStyles,
  Tooltip,
  Spinner,
} from "@fluentui/react-components";
import { Calendar, DateRangeType } from "@fluentui/react-calendar-compat";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { GridContactList } from "./GridContactList";
import { CalendarRegular } from "@fluentui/react-icons";
import { DropDownForMenuListPage } from "./DropDownForMenuListPage";
const API_URL_FORM = process.env.REACT_APP_REQUEST_FORM_API_URL;
const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
  button: {
    margin: "10px",
    backgroundColor: "#0078d4",
    color: "white",
    "&:hover": {
      backgroundColor: "#005A9E",
      color: "white",
    },
  },
  icon: {
    color: "white",
  },
});

export default function ContactsListRequestForm() {
  const companyId = sessionStorage.getItem("companyId") || "";
  const classes = useStyles();
  const [formId, setFormId] = useState(null);
  const [invisible, setInvisible] = useState(false);

  const getFormId = async () => {
    setIsLoadComplete(false);
    setIsSearchComplete(false);

    try {
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.get(`${API_URL_FORM}/forms/`, {
        headers: {
          "cid": `${companyId}`,
          "Authorization": "Bearer " + tokenResponse.accessToken,
        },
      });

      console.log("response formId", response.data);

      setFormId(response.data.id); // Assuming the form ID is returned directly in the response data
      setGetnewContacts(true);
    } catch (error) {
      console.error("Error fetching form ID:", error);
      if (error.response.data.statusCode === 404) {
        console.log(" CreatesFormsByCid");
        CreatesFormsByCid();
      }
    }
  };

  useEffect(() => {
    getFormId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreatesFormsByCid = async () => {
    try {
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.post(`${API_URL_FORM}/forms/`, {}, {
        headers: {
          "cid": `${companyId}`,
          "Authorization": "Bearer " + tokenResponse.accessToken,
        },
      });
      if (response.data.statusCode === 200) {
        getFormId();
      }
    } catch (error) {
      console.error("Error fetching form ID:", error);
    } finally {
      setGetnewContacts(true);
    }
  };

  const [getnewContacts, setGetnewContacts] = useState(false);
  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [isSearchComplete, setIsSearchComplete] = useState(false);

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const history = useHistory();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [companyDetailData, setCompanyDetailData] = useState(null);
  const [billingsType, setBillingsType] = useState("subscription");

  async function fetchCompanyData() {
    // console.log('userEffect retrieve company and accounts data!');
    setIsLoadComplete(false);
    setIsSearchComplete(false);
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log("tokenResponse: ", tokenResponse);
        axios
          .get(API_URL + "/companies-details", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
              "cid": companyId,
            },
          })
          .then((companiesDetailsResponse) => {
            // console.log('companiesDetailsResponse: ', companiesDetailsResponse);
            if (companiesDetailsResponse.data) {
              setCompanyDetailData(companiesDetailsResponse.data);
            }
            console.log("CompanyDetail", companiesDetailsResponse.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  }

  useEffect(() => {
    fetchCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchTerm = (value) => {
    if (!value) {
      setSearchTerm("");
    } else {
      setSearchTerm(value);
    }
  };

  const handleSearch = () => {
    try {
      setSelectedValueCurrentPage(`${t("requestFormContactsList.page")} 1`);
      setCurrentPage(1);
      setGetnewContacts(true);
      setText(`${t("requestFormContactsList.page")} 1`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {


    if (companyDetailData && companyDetailData.billings) {
      switch (companyDetailData.billings.type) {
        case "subscription":
          setCheckBillingLoading(false)
          setBillingsType("subscription");

          break;
        case "prepaid":
          setCheckBillingLoading(false)
          setBillingsType("prepaid");
          history.push({
            pathname: `/request-forms/not-subscribed`,
          });
          break;
        default:
          // Handle other cases if necessary
          setCheckBillingLoading(false)
          setBillingsType("prepaid");
          history.push({
            pathname: `/request-forms/not-subscribed`,
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetailData]);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [pageDropdown, setPageDropdown] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setsortDirection] = useState("DESC");
  const [checkBillingLoading, setCheckBillingLoading] = useState(true)



  const fetchData = async () => {
    setIsLoadComplete(false);
    setIsSearchComplete(false);
    console.log("fetchdata works!");
    try {

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.get(
        `${API_URL_FORM}/requests/formId/${formId}?from=${from}&to=${to}&offset=${(currentPage - 1) * selectedValue
        }&limit=${selectedValue}&delete=false&searchTerm=${searchTerm}&sortBy=c.createdTime&sortDirection=${sortDirection}`,
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );
      console.log("response", response);

      let _pageDropdown = [];

      for (
        let i = 1;
        i <= Math.ceil(response.data.countTotal / selectedValue);
        i++
      ) {
        _pageDropdown.push(i);
      }

      setPageDropdown(_pageDropdown);

      setData(response.data.requests);
      setCountTotal(response.data.countTotal);
      setGetnewContacts(false);
      setIsLoadComplete(true);
      setIsSearchComplete(true);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData([]);
      setPageDropdown([]);
      setCountTotal(0);
    }
  };

  const deleteDataById = async (id) => {
    try {
      // Set loading to true to indicate that the deletion process has started

      // const response = await axios.patch(
      //   `http://localhost:5004/api/requests/${id}`,
      //   { "replace": "/deleted", "value": true }
      // );

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.patch(`${API_URL_FORM}/requests/${id}`, {
        "replace": "/deleted",
        "value": true,
      }, {
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
        },
      });

      console.log("Data deleted successfully:", response);

      // Set loading to true to indicate that the deletion process has completed

      // Fetch data after successful deletion
      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      // Set loading to true to indicate that the deletion process has completed
      throw error; // Propagate the error to the caller
    }
  };


  const createDocumentfromContact = async (documentType, item) => {
    console.log(item);

    let documentPath = "/etax/documents/receipts";

    switch (documentType) {
      case "RECEIPT-TAXINVOICE":
        documentPath = "/etax/documents/receipts-taxinvoices";
        break;
      case "RECEIPT-TAXINVOICE-ABB":
        documentPath = "/etax/documents/receipts-taxinvoice-abb";
        break;
      case "RECEIPT":
        documentPath = "/etax/documents/receipts";
        break;
      case "INVOICE-TAXINVOICE":
        documentPath = "/etax/documents/invoices-taxinvoices";
        break;
      case "DELIVERYORDER-TAXINVOICE":
        documentPath = "/etax/documents/deliveryorders-taxinvoices";
        break;
      case "TAXINVOICE":
        documentPath = "/etax/documents/taxinvoices";
        break;
      case "DEBIT-NOTE":
        documentPath = "/etax/documents/debit-notes";
        break;
      case "CREDIT-NOTE":
        documentPath = "/etax/documents/credit-notes";
        break;
      case "QUOTATION":
        documentPath = "/documents/quotations";
        break;
      case "INVOICE":
        documentPath = "/documents/invoices";
        break;
      case "BILLING-NOTE":
        documentPath = "/documents/billing-notes";
        break;
      case "DELIVERYORDER":
        documentPath = "/documents/deliveryorders";
        break;
      case "EXPENSE-NOTE":
        documentPath = "/expenses/expense-notes";
        break;
      default:
        break;
    }

    const newObject = {
      ...item.data,
      addressLineOne: null,
      addressLineTwo: null,
      buyer: false,
      contactDepartment: "",
      contactEmail: "",
      contactName: "",
      contactNickName: "",
      contactPhone: "",
      contactPosition: "",
      fax: "",
      language: "th",
      payment: {
        accountName: "",
        banks: [
          {
            bankCode: "",
            bankName: "",
            branch: "",
            typeCode: "",
            typeName: "",
            accountNumber: "",
          },
        ],
        note: "",
      },
      seller: true,
      unstructure: false,
      website: "",
    };

    console.log("item before", item);
    console.log("newObject", newObject);

    history.push({
      pathname: documentPath,
      state: {
        command: "Contact",
        customer: newObject,
        contactSuggestion: newObject,
        backUrl: "/request-forms/contacts-client-list",
      },
    });
  };

  const EditPersonal = (id) => {
    // console.log(" find wrong id :", id);
    // console.log(" filtered Data :", filteredData);
    // console.log(" Data :", data);
    history.push({
      pathname: `/request-forms/contacts-client-list/contact-personal`,
      state: { id, data },
    });
  };

  useEffect(() => {
    if (location.state?.isback) {
      window.scrollTo(0, 0);
    }

    if (location.state?.editSuccess) {
      EditConfirm();
      history.replace({
        ...location, // keep the existing location
        state: {
          ...location.state, // keep the existing state
          editSuccess: undefined, // set editSuccess to undefined
        },
      });
    } else if (location.state?.editSuccess === false) {
      EditUnsuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMonth = (goBack) => {
    let monthNames = [
      t("requestFormDocuments.months01"),
      t("requestFormDocuments.months02"),
      t("requestFormDocuments.months03"),
      t("requestFormDocuments.months04"),
      t("requestFormDocuments.months05"),
      t("requestFormDocuments.months06"),
      t("requestFormDocuments.months07"),
      t("requestFormDocuments.months08"),
      t("requestFormDocuments.months09"),
      t("requestFormDocuments.months10"),
      t("requestFormDocuments.months11"),
      t("requestFormDocuments.months12"),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + " " + year,
    };
  };

  // const formatDate = (d) => {
  //   let month = "" + (d.getMonth() + 1);
  //   let day = "" + d.getDate();
  //   let year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [year, month, day].join("-");
  // };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return formatDate(_lastDay);
  };

  const [from, setFrom] = useState(
    sessionStorage.getItem("from") ||
    getFrom(getMonth(0).month, getMonth(0).year)
  );
  const [to, setTo] = useState(
    sessionStorage.getItem("to") || getTo(getMonth(0).month, getMonth(0).year)
  );

  const [date, setDate] = useState(
    sessionStorage.getItem("date")
      ? new Date(sessionStorage.getItem("date"))
      : new Date()
  );

  const DayPickerStrings = {
    months: [
      t("requestFormDocuments.months01"),
      t("requestFormDocuments.months02"),
      t("requestFormDocuments.months03"),
      t("requestFormDocuments.months04"),
      t("requestFormDocuments.months05"),
      t("requestFormDocuments.months06"),
      t("requestFormDocuments.months07"),
      t("requestFormDocuments.months08"),
      t("requestFormDocuments.months09"),
      t("requestFormDocuments.months10"),
      t("requestFormDocuments.months11"),
      t("requestFormDocuments.months12"),
    ],

    shortMonths: [
      t("requestFormDocuments.shortMonths01"),
      t("requestFormDocuments.shortMonths02"),
      t("requestFormDocuments.shortMonths03"),
      t("requestFormDocuments.shortMonths04"),
      t("requestFormDocuments.shortMonths05"),
      t("requestFormDocuments.shortMonths06"),
      t("requestFormDocuments.shortMonths07"),
      t("requestFormDocuments.shortMonths08"),
      t("requestFormDocuments.shortMonths09"),
      t("requestFormDocuments.shortMonths10"),
      t("requestFormDocuments.shortMonths11"),
      t("requestFormDocuments.shortMonths12"),
    ],

    days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

    shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

    goToToday: t("requestFormContactsList.goToCurrent"),
    prevMonthAriaLabel: "ไปเดือนที่แล้ว",
    nextMonthAriaLabel: "ไปเดือนถัดไป",
    prevYearAriaLabel: "ไปปีที่แล้ว",
    nextYearAriaLabel: "ไปปีถัดไป",
    closeButtonAriaLabel: "ปิด",
    monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
    yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

    // isRequiredErrorMessage: t("requestFormDocuments.isRequiredErrorMessage"),

    invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
  };

  const onFormatDate = (date, format) => {
    if (format === "CE") {
      return (
        `${date.getDate()}`.padStart(2, "0") +
        "/" +
        `${date.getMonth() + 1}`.padStart(2, "0") +
        "/" +
        date.getFullYear()
      );
    } else {
      return (
        `${date.getDate()}`.padStart(2, "0") +
        "/" +
        `${date.getMonth() + 1}`.padStart(2, "0") +
        "/" +
        (date.getFullYear() + 543)
      );
    }
  };

  function getDateText(monthObject) {
    if (monthObject.type === "dateRange") {
      if (i18n.language === "th") {
        return (
          onFormatDate(fromDate, "BE") + " - " + onFormatDate(toDate, "BE")
        );
      }
      return onFormatDate(fromDate, "CE") + " - " + onFormatDate(toDate, "CE");
    }
    if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month")
      return t("requestFormDocuments.thisMonth");

    let monthNames = [
      t("requestFormDocuments.months01"),
      t("requestFormDocuments.months02"),
      t("requestFormDocuments.months03"),
      t("requestFormDocuments.months04"),
      t("requestFormDocuments.months05"),
      t("requestFormDocuments.months06"),
      t("requestFormDocuments.months07"),
      t("requestFormDocuments.months08"),
      t("requestFormDocuments.months09"),
      t("requestFormDocuments.months10"),
      t("requestFormDocuments.months11"),
      t("requestFormDocuments.months12"),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === "th") {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + " " + _year;
  }

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("queryMonth-key") && {
      key: sessionStorage.getItem("queryMonth-key"),
      text: sessionStorage.getItem("queryMonth-text"),
      ...(sessionStorage.getItem("queryMonth-type")
        ? { type: sessionStorage.getItem("queryMonth-type") }
        : {}),
    }) || {
      key: getMonth(0).year + "" + getMonth(0).key,
      text: t("requestFormDocuments.thisMonth"),
    }
  );

  const [toDate, setToDate] = useState(
    sessionStorage.getItem("toDate")
      ? new Date(sessionStorage.getItem("toDate"))
      : null
  );
  const [timeRangeError, setTimeRangeError] = useState(false);

  const [timeRangeDate, setTimeRangeDate] = useState(false);

  const handleTimeRangeDate = () => {
    if (sessionStorage.getItem("queryMonth-type") === "dateRange") {
      setTimeRangeDate(true);
    } else {
      setTimeRangeDate(false);
    }
  };

  useEffect(() => {
    handleTimeRangeDate();
  }, []);

  const [calenderOpenUp, setCalenderOpenUp] = useState(false);

  const turnUpCalender = () => {
    setCalenderOpenUp((prev) => !prev);
    // setTimeRangeDate((prev) => !prev);
  };

  const [fromDate, setFromDate] = useState(
    sessionStorage.getItem("fromDate")
      ? new Date(sessionStorage.getItem("fromDate"))
      : null
  );
  const [text, setText] = useState(`${t("requestFormContactsList.page")} 1`);
  const [textPerPage, setTextPerPage] = useState(
    `10 ${t("requestFormContactsList.documents")}`
  );
  const [selectedValue, setSelectedValue] = useState(10);

  const [selectedOptions, setSelectedOptions] = useState([1]);
  const [selectedOptionsValuePerPage, setSelectedOptionsValuePerPage] =
    useState(["10-document-per-page"]);
  const [selectedValueCurrentPage, setSelectedValueCurrentPage] = useState(
    `${t("requestFormContactsList.page")} 1`
  );
  const handleonClick = (newValue) => {
    setSelectedValue(newValue);
    setSelectedValueCurrentPage(`${t("requestFormContactsList.page")} 1`);
    setCurrentPage(1);
    setSelectedOptions([1]);
    setPageSize(newValue);
    setGetnewContacts(true);
    setTextPerPage(`${newValue} ${t("requestFormContactsList.documents")}`);
    setText(`${t("requestFormContactsList.page")} 1`);
    setCheckedValues({ page: [1] });
  };

  const handleonClickCurrentPage = (newValue) => {
    setCurrentPage(newValue);
    setSelectedOptions([newValue]);
    const Value = String(newValue);
    setSelectedValueCurrentPage(Value);
    setText(`${t("requestFormContactsList.page")} ${newValue}`);
    setGetnewContacts(true);
  };

  const [checkedValues, setCheckedValues] = useState({ page: [1] });

  const onChange = (e, { name, checkedItems }) => {
    setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
  };

  const [checkedValuesDocument, setCheckedValuesDocument] = useState({
    "documents": [1],
  });

  const onChangeDocuments = (e, { name, checkedItems }) => {
    setCheckedValuesDocument((s) => ({ ...s, [name]: checkedItems }));
  };

  useEffect(() => {
    if (selectedValue !== undefined && selectedValue !== null) {
      const newText = selectedValueCurrentPage;
      setText(newText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (getnewContacts) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, getnewContacts]);

  const [countTotal, setCountTotal] = useState(0);

  const monthNames = [
    t("requestFormDocuments.months01"),
    t("requestFormDocuments.months02"),
    t("requestFormDocuments.months03"),
    t("requestFormDocuments.months04"),
    t("requestFormDocuments.months05"),
    t("requestFormDocuments.months06"),
    t("requestFormDocuments.months07"),
    t("requestFormDocuments.months08"),
    t("requestFormDocuments.months09"),
    t("requestFormDocuments.months10"),
    t("requestFormDocuments.months11"),
    t("requestFormDocuments.months12"),
  ];
  const handleDateSelect = (_date) => {
    setDate(_date);
    sessionStorage.setItem("date", _date.toISOString());

    let adjustDate = _date;
    setToDate(adjustDate);

    let year = adjustDate.getFullYear() + 543;
    let monthKey = `${adjustDate.getFullYear()}${String(
      adjustDate.getMonth() + 1
    ).padStart(2, "0")}`;
    let monthText = `${monthNames[adjustDate.getMonth()]} ${year}`;

    setQueryMonth({ key: monthKey, text: monthText });

    sessionStorage.setItem("queryMonth-type", "");
    sessionStorage.setItem("queryMonth-key", monthKey);
    sessionStorage.setItem("queryMonth-text", monthText);

    let from = getFrom(adjustDate.getMonth(), adjustDate.getFullYear());
    let to = getTo(adjustDate.getMonth(), adjustDate.getFullYear());

    setFrom(from);
    setTo(to);

    sessionStorage.setItem("fromDate", from);
    sessionStorage.setItem("toDate", to);
    sessionStorage.setItem("rangeDate", false);
    sessionStorage.setItem("from", from);
    sessionStorage.setItem("to", to);
    setSearchTerm("");
    setPageDropdown([]);
    setCountTotal(0);
    setGetnewContacts(true);
    setCalenderOpenUp(false);

    // Assuming that fetchData should be called to update data
  };

  const EditConfirm = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("requestFormContactsList.saveSuccessfully")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );

  const EditUnsuccess = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("requestFormContactsList.saveUnsucess")}
        </ToastTitle>
      </Toast>,
      { intent: "error" }
    );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Toaster toasterId={toasterId} />
        {checkBillingLoading ?
          <div style={{
            position: "relative", // Ensure the spinner is constrained to this div
            width: "1000px",
            height: "100%",

          }}>
            <div style={{
              position: "absolute",   // Makes the spinner fill the parent div
              top: 0,
              left: 0,
              width: "100%",          // Full width of the parent div
              height: "500px",         // Full height of the parent div
              display: "flex",
              justifyContent: "center", // Centers the spinner horizontally
              alignItems: "center",     // Centers the spinner vertically
              color: "black",
              fontSize: "20px",

            }}>
              <div style={{ marginRight: "10px", marginTop: "5px", minWidth: "150px" }}>
                <Spinner size="huge" label={t("requestFormAdminFormEditor.loading")} />
              </div>
            </div>
          </div>
          :


          <section
            style={{
              minHeight: "2500px",
              fontFamily: "Segoe UI, sans-serif",
              flexDirection: "column",
              width: "100%",
            }}

          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {" "}
              <h2 style={{ color: "black", marginLeft: "2%", marginTop: "25px" }}>
                <Icon iconName="ContactList" style={{ color: "#106ebe" }} />{" "}
                {t("requestFormContactsList.listOfApplicant")}
              </h2>
              <Tooltip
                content={t("requestFormContactsList.descriptionFormManagement")}
              >
                <MenuButton
                  icon={<QrCodeFilled />}
                  className={classes.button}
                  menuIcon=""
                  onClick={() => {
                    history.push({
                      pathname: `/request-forms/management-form`,
                    });
                  }}
                >
                  <Text> {t("requestFormContactsList.linkToManagement")}</Text>
                </MenuButton>
              </Tooltip>
            </div>{" "}
            {billingsType === "subscription" && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: !invisible ? "1115px" : `1100px`,
                    height: "30px",
                    marginTop: "10px",
                    position: "relative",
                    zIndex: "2",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    paddingTop: "10px",
                    paddingBottom: "30px",

                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      minWidth: "460px",
                      position: "relative",
                      marginLeft: "25px",
                    }}
                  >
                    <MenuButton
                      onClick={() => {
                        turnUpCalender();
                      }}
                      style={{
                        display: "flex",
                        height: "33px",
                        marginRight: "10px",
                      }}
                      disabled={!isLoadComplete || !isSearchComplete}
                      icon={<CalendarRegular />}
                    >
                      <Text> {queryMonth ? getDateText(queryMonth) : "None"}</Text>
                    </MenuButton>
                    {calenderOpenUp && (
                      <div
                        style={{
                          display: "flex",
                          width: "150px",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          position: "absolute",
                          top: "40px",
                        }}
                      >
                        <div
                          style={{
                            width: "220px",
                            height: "30px",
                            paddingTop: "0px",
                            backgroundColor: "white",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <Checkbox
                            // label={t("sellSummary.timeRange")}
                            label={
                              <Text
                                style={{
                                  width: "150px",
                                }}
                              >
                                {t("requestFormContactsList.pickRangeTime")}
                              </Text>
                            }
                            checked={timeRangeDate}
                            onChange={(e) => {
                              setTimeRangeDate((prev) => !prev);
                            }}
                          />
                        </div>
                        <div
                          style={{
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "white",
                            padding: "0px 0px 25x 5px",
                            width: "220px",
                          }}
                        >
                          {timeRangeDate === true && (
                            <div
                              style={{
                                padding: "20px",
                                paddingTop: "5px",
                              }}
                            >
                              <div
                                styles={{
                                  height: 42,

                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "20%",
                                  }}
                                >
                                  <Text style={{ color: "black" }}>
                                    {" "}
                                    {t("requestFormContactsList.from")}
                                  </Text>
                                </div>
                                <div styles={{ display: "flex", width: "80%" }}>
                                  <DatePicker
                                    placeholder={t(
                                      "requestFormRequestList.pleaseSelect"
                                    )}
                                    strings={DayPickerStrings}
                                    value={fromDate}
                                    maxDate={toDate}
                                    formatDate={onFormatDate}
                                    onSelectDate={(_date) => {
                                      setFromDate(_date);

                                      setTimeRangeError(false);
                                      if (
                                        Math.ceil(
                                          Math.abs(_date - toDate) /
                                          (1000 * 60 * 60 * 24)
                                        ) > 30
                                      )
                                        setTimeRangeError(true);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <Text
                                    style={{ color: "black", marginleft: "20px" }}
                                  >
                                    {t("requestFormContactsList.to")}
                                  </Text>
                                </div>
                                <div
                                  styles={{
                                    root: {
                                      width: "80%" /* alignItems: 'center' */,
                                    },
                                  }}
                                >
                                  <DatePicker
                                    placeholder={t(
                                      "requestFormRequestList.pleaseSelect"
                                    )}
                                    strings={DayPickerStrings}
                                    value={toDate}
                                    minDate={fromDate}
                                    formatDate={onFormatDate}
                                    onSelectDate={(_date) => {
                                      setToDate(_date);

                                      setTimeRangeError(false);
                                      if (
                                        Math.ceil(
                                          Math.abs(_date - fromDate) /
                                          (1000 * 60 * 60 * 24)
                                        ) > 30
                                      )
                                        setTimeRangeError(true);
                                    }}
                                  />
                                </div>
                              </div>
                              {timeRangeError ? (
                                <div
                                  styles={{
                                    root: {
                                      padding: "12px 0px 0px 12px",
                                    },
                                  }}
                                >
                                  <Text
                                    styles={{
                                      root: { fontSize: 12, color: "#A4262C" },
                                    }}
                                  >
                                    {t("requestFormRequestList.timeRangeError")}
                                  </Text>
                                </div>
                              ) : null}
                              <div
                                styles={{
                                  root: {
                                    width: "92%",
                                  },
                                }}
                              >
                                <Button
                                  appearance="primary"
                                  text={t("requestFormRequestList.search")}
                                  onClick={() => {
                                    if (!timeRangeError && fromDate && toDate) {
                                      setQueryMonth({
                                        type: "dateRange",
                                        key:
                                          formatDate(fromDate) +
                                          ":" +
                                          formatDate(toDate),
                                        text:
                                          formatDate(fromDate) +
                                          " - " +
                                          formatDate(toDate),
                                      });

                                      sessionStorage.setItem(
                                        "queryMonth-type",
                                        "dateRange"
                                      );
                                      sessionStorage.setItem(
                                        "queryMonth-key",
                                        formatDate(fromDate) +
                                        ":" +
                                        formatDate(toDate)
                                      );
                                      sessionStorage.setItem(
                                        "queryMonth-text",
                                        formatDate(fromDate) +
                                        " - " +
                                        formatDate(toDate)
                                      );
                                      setFrom(
                                        getFromDMY(
                                          fromDate.getDate(),
                                          fromDate.getMonth(),
                                          fromDate.getFullYear()
                                        )
                                      );
                                      setTo(
                                        getToDMY(
                                          toDate.getDate(),
                                          toDate.getMonth(),
                                          toDate.getFullYear()
                                        )
                                      );

                                      sessionStorage.setItem(
                                        "from",
                                        getFromDMY(
                                          fromDate.getDate(),
                                          fromDate.getMonth(),
                                          fromDate.getFullYear()
                                        )
                                      );
                                      sessionStorage.setItem(
                                        "to",
                                        getToDMY(
                                          toDate.getDate(),
                                          toDate.getMonth(),
                                          toDate.getFullYear()
                                        )
                                      );

                                      sessionStorage.setItem("fromDate", from);
                                      sessionStorage.setItem("toDate", to);
                                      // sessionStorage.getItem("toDate")
                                      //   ? new Date(sessionStorage.getItem("toDate"))
                                      //   : null;
                                      setSearchTerm("");
                                      setPageDropdown([]);
                                      setCountTotal(0);
                                      setGetnewContacts(true);
                                      setCalenderOpenUp(false);
                                    }
                                  }}
                                  style={{ width: "125px", letterSpacing: "1px" }}
                                >
                                  {t("requestFormContactsList.search")}
                                </Button>
                              </div>
                            </div>
                          )}
                          {timeRangeDate === false && (
                            <>
                              {/* <p>
                          Selected Date:{" "}
                          {selectedDate ? selectedDate.toDateString() : "None"}
                        </p>
                        <p>Date Range: {dateRangeString}</p> */}
                              <div
                                style={{
                                  display: "flex",

                                  color: "black",
                                }}
                              >
                                <Calendar
                                  disabled={!isLoadComplete || !isSearchComplete}
                                  styles={{ color: "black" }}
                                  onSelectDate={handleDateSelect}
                                  isMonthPickerVisible={true}
                                  highlightSelectedMonth={true}
                                  isDayPickerVisible={false}
                                  dateRangeType={DateRangeType.Day}
                                  autoNavigateOnSelection={false}
                                  value={date}
                                  strings={DayPickerStrings}
                                  showSixWeeksByDefault={true}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        minWidth: "140px",
                        marginRight: "10px",
                      }}
                    >
                      <DropDownForContactsList
                        onClick={handleonClick}
                        selectedValue={selectedValue}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        setSelectedOptions={setSelectedOptions}
                        currentPage={currentPage}
                        setSelectedValueCurrentPage={setSelectedValueCurrentPage}
                        setSelectedOptionsValuePerPage={setSelectedOptionsValuePerPage}
                        selectedOptionsValuePerPage={selectedOptionsValuePerPage}
                        textPerPage={textPerPage}
                        isLoadComplete={isLoadComplete}
                        isSearchComplete={isSearchComplete}
                        setSelectedValue={setSelectedValue}
                        checkedValuesDocument={checkedValuesDocument}
                        onChangeDocuments={onChangeDocuments}
                      />
                    </div>
                    {(countTotal > 0) &&
                      <div
                        style={{
                          display: "flex",
                          minWidth: "85px",
                          marginRight: "10px",
                        }}
                      >
                        <DropDownForMenuListPage
                          onClick={handleonClickCurrentPage}
                          selectedValue={selectedValueCurrentPage}
                          selectedValueFromContactsList={selectedValue}
                          pageDropdown={pageDropdown}
                          text={text}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          currentPage={currentPage}
                          isLoadComplete={isLoadComplete}
                          isSearchComplete={isSearchComplete}
                          selectedValueCurrentPage={selectedValueCurrentPage}
                          checkedValues={checkedValues}
                          onChange={onChange}
                        />
                      </div>}

                    <div style={{ display: "flex" }}>
                      <Button
                        icon={<ArrowClockwiseRegular style={{ width: "20px" }} />}
                        style={{ minWidth: "30px", maxWidth: "80px", height: "32px", border: "none" }}
                        onClick={() => {
                          fetchData();
                        }}
                        disabled={!isLoadComplete}
                      >
                        <Text>{t("requestFormContactsList.refresh")}</Text>
                      </Button>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      minWidth: "400px",
                      marginRight: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginRight: "10px",
                      }}
                    >
                      <SearchBox
                        size="medium"
                        placeholder={t("requestFormContactsList.searchBy")}
                        type="text"
                        value={searchTerm || ""}
                        style={{ minWidth: "300px" }}
                        onChange={(e) => handleSearchTerm(e.currentTarget.value)}
                        aria-disabled={true}
                        disabled={!isLoadComplete}
                      />
                    </div>
                    <div style={{ maxWidth: "80px" }}>
                      <Button
                        appearance="primary"
                        text={t("requestFormRequestList.search")}
                        onClick={() => {
                          handleSearch();
                        }}
                        disabled={!isLoadComplete}
                      >
                        {t("requestFormContactsList.search")}
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "1100px",

                    minHeight: "500px",
                    position: "relative",
                    zIndex: "1",
                  }}
                >
                  <GridContactList
                    data={data}
                    isLoadComplete={isLoadComplete}
                    isSearchComplete={isSearchComplete}
                    EditPersonal={EditPersonal}
                    deleteDataById={deleteDataById}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    fetchData={fetchData}
                    countTotal={countTotal}
                    perPage={selectedValue}
                    handleonClickCurrentPage={handleonClickCurrentPage}
                    setGetnewContacts={setGetnewContacts}
                    sortDirection={sortDirection}
                    setsortDirection={setsortDirection}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    setText={setText}
                    getnewContacts={getnewContacts}
                    createDocumentfromContact={createDocumentfromContact}
                    invisible={invisible}
                    setInvisible={setInvisible}
                  />
                </div>
              </div>
            )}
          </section>
        }
      </div>
    </>
  );
}
