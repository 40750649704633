import * as React from "react";
import { useState, useEffect } from "react";
import {
  makeStyles,
  shorthands,
  Text,
  MenuButton,
  MenuTrigger,
  MenuPopover,
  MenuList,
  Menu,
  MenuItemRadio,
} from "@fluentui/react-components";
// import "./SelectTemplateApp.css";


import { useTranslation } from "react-i18next";
import { DocumentRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    minWidth: "50px",
  },
});

export const DropDownForMenuListPage = ({
  onClick,
  pageDropdown,
  selectedValueFromContactsList,
  setSelectedOptions,
  currentPage,
  isLoadComplete,
  isSearchComplete,
  checkedValues,
  onChange,
}) => {
  const { t } = useTranslation();

  const styles = useStyles();

  useEffect(() => {
    setDropdownOption(pageDropdown);
    setSelectedOptions([dropdownOption[currentPage - 1]?.key]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDropdown, selectedValueFromContactsList]);

  const [dropdownOption, setDropdownOption] = useState([1]);

  return (
    <div className={styles.root}>
      <div style={{ display: "flex", minWidth: "50px" }}>

        <Menu
          checkedValues={checkedValues}
          onCheckedValueChange={onChange}
          closeOnScroll={true}
        >
          <MenuTrigger>
            <MenuButton
              icon={<DocumentRegular />}
              disabled={!isLoadComplete || !isSearchComplete}
              style={{ fontWeight: "normal" }}
            >
              {t("requestFormContactsList.page")} {currentPage}
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              {dropdownOption.map((option, index) => (
                <MenuItemRadio
                  key={index + 1}
                  value={index + 1}
                  onClick={() => onClick(index + 1)}
                  onSelect={currentPage}
                  icon={<DocumentRegular />}
                  name="page"
                  disabled={!isLoadComplete || !isSearchComplete}
                >
                  <Text >
                    {" "}
                    {t("requestFormContactsList.page")} {index + 1}
                  </Text>
                </MenuItemRadio>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};
