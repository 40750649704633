import React, { useEffect, useState } from "react";
import { Spinner, MenuButton, makeStyles } from "@fluentui/react-components";
import PdfAllPages from "../PdfAllPages";
import { SaveRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { CardPreview } from "@fluentui/react-components";
import {
  ArrowHookUpLeftRegular,
  ArrowUploadRegular,
  ArrowCircleRightRegular,
  ArrowCircleLeftRegular,
  ArrowClockwiseDashesRegular,
  DismissRegular
} from "@fluentui/react-icons";
import TemplatesSelectStructure from "../TemplatesSelectStructure.js";
import {
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  MessageBarActions,
} from "@fluentui/react-components";
import {
  Toaster,
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  useId,
  Link,
  Switch,
} from "@fluentui/react-components";
import axios from "axios";
import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  // SplitButton,
  Button,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Divider
} from "@fluentui/react-components";
import { useLocation, useHistory } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";
import { ExtractNumberNameFromStyleId } from "../ExtractNumberNameFromStyleId.js";
const API_URL = process.env.REACT_APP_API_URL


const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    color: "darkgrey",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    padding: "1%",
    zIndex: "10002"
  },
  selected: {
    backgroundColor: "#80d4ff",
  },
  hover: {
    backgroundColor: "#efeff5",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    bottom: "60px",
    left: "10%",
  },
  hoverEffect: {
    transform: "revert",
  },
  cardPreview: {
    border: "1px solid black",
    width: "450px",
    minWidth: "unset",
    maxWidth: "600px",
    marginTop: "20%"
  },
  controlCardSlide: {
    width: "90%",
    height: "210px",
    backgroundColor: "rgb(212, 239, 252)",
    borderBottomLeftRadius: "17%",
    borderBottomRightRadius: "17%",
    display: "flex",
    fontSize: "24px",
    marginBottom: "1%"
  },
  slideCard: {
    border: "1px solid grey",
    width: "120px",
    zIndex: "10001"
  },

});
const TemplatesExample = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const history = useHistory()
  const companyId = sessionStorage.getItem('companyId') || '';
  const handleBack = () => {
    history.push({
      pathname: "/templates",
      state: {
        selectedTemplateConfirm: data.selectedTemplateConfirm,
        selectedTemplate: data.selectedTemplate,
        templates: data.templates,
        searchId: data.searchId,
        nameTemplate: data.nameTemplate,
        selectedValue: data.selectedValue,
        picture: data.picture,
        allTemplates: data.allTemplates,
        currentPage: data.currentPage,
        pathName: "back"
      },
    })
    setResponseExampleFromServer(null);
  };
  const { t } = useTranslation();

  const intentsReset = ["warning"];

  const [responseExampleFromServer, setResponseExampleFromServer] =
    useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const getFile = async (templatesNumber, DocumentType, formData) => {
    try {
      setIsLoading(true);
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      // const response = await axios.post(
      //   `http://localhost:7073/api/templates/examples/pdf/preview?templateNumber=${templatesNumber}&documentType=${DocumentType}`,
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //       "cid": companyId,
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     },
      //     responseType: "blob",
      //   }
      // );

      const response = await axios.post(
        `${API_URL}/templates/examples/pdf/preview?templateNumber=${templatesNumber}&documentType=${DocumentType}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "cid": companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
          responseType: "blob",
        }
      );
      console.log("response data from get", response.data);

      setResponseExampleFromServer(window.URL.createObjectURL(response.data));
    } catch (error) {
      console.error("Error getting file", error);
    } finally {
      setIsLoading(false);
    }
  };

  const location = useLocation()
  const data = location.state;
  const [logoFromUploadChanged, setlogoFromUploadChanged] = useState(null)
  const [signatureFromUploadChanged, setSignatureFromUploadChanged] = useState(null)
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    if (data.blankUpload === true) {
      if (logoFromUploadChanged !== data.companyLogo) {
        setEdited(true)
      }
      if (signatureFromUploadChanged !== data.companySignature) {
        setEdited(true)
      }
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (data?.blankUpload === undefined) {
      if (data.currentCardNumber === data.searchId) {
        if (data.companyLogo !== null) {
          setlogoFromUploadChanged(data.companyLogo)
        } else {
          setlogoFromUploadChanged(null)
        }
        if (data.companySignature !== null) {
          setSignatureFromUploadChanged(data.companySignature)
        } else {
          setSignatureFromUploadChanged(null)
        }
      }
    }
    // eslint-disable-next-line
  }, [])


  const getImagesFromTemplates = async (templatesNumber, DocumentType, formData) => {
    try {
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      // const response = await axios.get(
      //   `http://localhost:7071/api/templates/images?templateNumber=${templatesNumber}&documentType=${DocumentType}`,
      //   {
      //     headers: {
      //       "cid": companyId,
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     },
      //   }
      // );

      const response = await axios.get(
        `${API_URL}/templates/images?templateNumber=${templatesNumber}&documentType=${DocumentType}`,
        {
          headers: {
            "cid": companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }
      );


      console.log("response Images from get", response.data);



      return response.data
    } catch (error) {
      console.error("Error getting file", error);
    }
  };

  const base64ToFile = (base64, fileName, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  };

  useEffect(() => {
    setSelectedTemplateConfirm(data.selectedTemplateConfirm)
    const formData = new FormData();

    const fetchImages = async () => {
      const responseDownloadfromTemplates = await getImagesFromTemplates(
        data.selectedTemplateConfirm.styleId,
        data.selectedTemplateConfirm.type
      );

      if (responseDownloadfromTemplates) {
        const logoBase64 = responseDownloadfromTemplates.logo;
        const signatureBase64 = responseDownloadfromTemplates.signature;

        // แปลง Base64 เป็นไฟล์
        const logoFile = logoBase64 ? base64ToFile(logoBase64, "logo.png", "image/png") : null;
        const signatureFile = signatureBase64 ? base64ToFile(signatureBase64, "signature.png", "image/png") : null;

        console.log("Logo File:", logoFile);
        console.log("Signature File:", signatureFile);

        setCompanyLogo(logoFile);
        setCompanySignature(signatureFile);
        setlogoFromUploadChanged(logoFile)
        setSignatureFromUploadChanged(signatureFile)

        // เพิ่มไฟล์ลงใน formData
        formData.append("logo", logoFile || null);  // หากไม่มี logoFile ให้ใส่ null
        formData.append("signature", signatureFile || null);  // หากไม่มี signatureFile ให้ใส่ null

        // เรียกใช้ getFile โดยไม่ต้องตรวจสอบไฟล์
        await getFile(data.selectedTemplateConfirm.styleId, data.selectedTemplateConfirm.type, formData);
      }
    };

    // เรียกฟังก์ชัน fetchImages เมื่อค่าของโลโก้หรือลายเซ็นยังไม่ถูกตั้งค่า
    if (data.companyLogo === undefined || data.companySignature === undefined) {
      fetchImages();
    }
    // eslint-disable-next-line
  }, [data.selectedTemplateConfirm.styleId, data.selectedTemplateConfirm.type]);


  useEffect(() => {
    if (data.companyLogo !== undefined || data.companySignature !== undefined) {
      const formData = new FormData();
      if (data.companyLogo) {
        setCompanyLogo(data.companyLogo)
        formData.append("logo", data.companyLogo);
      }

      if (data.companySignature) {
        setCompanySignature(data.companySignature)
        formData.append("signature", data.companySignature);
      }

      getFile(data.selectedTemplateConfirm.styleId, data.selectedTemplateConfirm.type, formData);
    }
    // eslint-disable-next-line
  }, [])

  const [open, setOpen] = React.useState(false);
  const [chooseFix, setChooseFix] = useState(null);
  const [openSecond, setOpenSecond] = React.useState(false);
  const [openResetFirst, setOpenResetFirst] = React.useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companySignature, setCompanySignature] = useState(null)
  const [startIndex, setStartIndex] = useState(0);
  const cardsPerPage = 5
  const [hoveredTemplateId, setHoveredTemplateId] = useState(null);
  const [selectedTemplateConfirm, setSelectedTemplateConfirm] = useState(null);
  const [allTemplates, setAllTemplates] = useState(data?.allTemplates || []);
  const [isEnabled, setIsEnabled] = useState(null);
  const classes = useStyles();


  useEffect(() => {
    setAllTemplates(data?.allTemplates || []);
    // eslint-disable-next-line
  }, [data.allTemplates]);


  const pickThisTemplate = (template) => {
    setSelectedTemplateConfirm(template)
    const formData = new FormData();
    if (data.companyLogo !== null || data.companySignature !== null) {

      if (data?.companyLogo !== null) {
        // Use the correct field name 'logo' and set the file name as 'logo.png'
        setCompanyLogo(data.companyLogo)
        formData.append("logo", data.companyLogo);
      } else {
        setCompanyLogo(null)

      }

      if (data?.companySignature !== null) {
        // Use the correct field name 'signature' and set the file name as 'signature.png'
        setCompanySignature(data.companySignature)
        formData.append("signature", data.companySignature);
      }
    } else {
      setCompanyLogo(null)
      setCompanySignature(null)
    }

    getFile(template.styleId, template.type, formData)
  }

  const selectableTemplates = allTemplates?.filter((_template) => {
    const selectedStyleId = data.selectedTemplate?.styleId;
    return _template?.styleId && selectedStyleId &&
      _template.styleId.slice(0, 4) === selectedStyleId.slice(0, 4);
  }) || [];

  if (selectableTemplates.length === 0) {
    console.log('No templates available for this selection.');
  }

  const totalCards = selectableTemplates.length;

  const visibleTemplates = selectableTemplates.slice(startIndex, startIndex + cardsPerPage)

  if (visibleTemplates.length === 0) {
    console.log('No templates available for this selection.');
  }

  const hasNext = startIndex + cardsPerPage < totalCards;
  const hasPrev = startIndex > 0;

  const handleNextCard = () => {
    if (hasNext) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrevCard = () => {
    if (hasPrev) {
      setStartIndex(startIndex - 1);
    }
  };

  // const primaryActionButtonProps = {
  //   onClick,
  //Use when feature save all is done.
  // };

  // function onClick() {
  //   setOpenSecond(true);
  //   setChooseFix("all");
  //Use when feature save all is done.
  // }

  // function onClick() {
  //   setOpenSecond(true);
  //   setChooseFix(selectedTemplateConfirm.type);
  //Use when feature save all is done.
  // }

  const handleConfirmTemplate = async (selectedCard, chooseFix) => {
    try {
      setTextSpinner(t("templatesSelector.saving"))
      setIsLoading(true);
      inProgress()
      setDismissTimeout(40000)
      if (chooseFix === "all") {
        const formData = new FormData();
        if (companyLogo) {
          formData.append("logo", companyLogo);
        }
        if (companySignature) {
          formData.append("signature", companySignature);
        }
        await handleSave(selectedCard, chooseFix, formData);
      } else {
        const formData = new FormData();
        if (companyLogo) {
          formData.append("logo", companyLogo);
        }
        if (companySignature) {
          formData.append("signature", companySignature);
        }
        await handleSave(selectedCard, data.selectedValue, formData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (templateNumber, documentType, formData) => {
    try {
      setIsLoading(true);
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.put(
        `${API_URL}/templates?templateNumber=${templateNumber}&documentType=${documentType}`,
        formData,
        {
          headers: {
            "cid": companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          }
        }
      )

      // const response = await axios.put(
      //   `${API_URL}/templates?templateNumber=${templateNumber}&documentType=${documentType}`,
      //   formData,
      //   {
      //     headers: {
      //       "cid": companyId,
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     }
      //   }
      // )
      if (response.data) {
        setIsLoading(false)
        setDismissTimeout(0)
        setEdited(false)
        console.log(response.data);
        history.push({
          pathname: "/templates",
          state: {
            selectedTemplateConfirm: data.selectedTemplateConfirm,
            selectedTemplate: data.selectedTemplate,
            templates: data.templates,
            searchId: data.searchId,
            nameTemplate: data.nameTemplate,
            selectedValue: data.selectedValue,
            picture: data.picture,
            allTemplates: data.allTemplates,
            currentPage: data.currentPage,
            pathName: "save",
            blankUpload: data.blankUpload,
            triggerToaster: true
          }
        })
      }
    } catch (error) {
      console.error("Error saving HTML content:", error);
      EditUnsuccess();
      setDismissTimeout(0)
    } finally {

    }
  };

  const handleConfirmReset = async (styleId, chooseFix) => {
    try {
      setIsLoading(true);
      inProgress()
      setDismissTimeout(40000)


      // TemplateConfirm();
      if (chooseFix === "all") {
        const formData = new FormData();
        formData.append("logo", null);
        formData.append("signature", null);
        await handleReset(styleId, chooseFix, formData);
      } else {
        const formData = new FormData();
        formData.append("logo", null)
        formData.append("signature", null)
        await handleReset(styleId, chooseFix, formData);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleReset = async (styleId, documentType, formData) => {
    try {
      setIsLoading(true);
      console.log("Html reset successfully.");
      setDismissTimeout(0)
      if (logoFromUploadChanged !== null || signatureFromUploadChanged !== null) {
        setEdited(true)
      }
      setCompanyLogo(null)
      setCompanySignature(null)
      getFile(styleId, documentType, formData)
      EditReset();
    } catch (error) {
      console.error("Error Reset HTML content:", error);
      EditUnsuccess();
      setDismissTimeout(0)
    } finally {
      setDismissTimeout(0)
    }
  };

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const EditUnsuccess = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("TemplatesExample.failToSave")}
        </ToastTitle>
      </Toast>,
      { intent: "error" }
    );

  const EditReset = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("TemplatesExample.resetTemplateAlready")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );

  const [timeout, setDismissTimeout] = React.useState(0);

  const inProgress = () =>
    dispatchToast(
      <Toast >
        <ToastTitle

          media={<Spinner size="tiny" />}
        >
          {t("TemplatesExample.sendingInProgress")}
        </ToastTitle>
      </Toast>,
      { timeout, intent: "info" }
    );

  const [textSpinner, setTextSpinner] = useState(t("TemplatesExample.loading"))
  const [cautionPastUsers, setCautionPastUser] = useState(true)
  return (
    <>
      <TemplatesSelectStructure>
        {edited &&
          <div style={{ width: "100%", color: "black" }}>
            {intentsReset.map((intent) => (
              <MessageBar key={intent} intent={intent} >
                <MessageBarBody>
                  <MessageBarTitle>{t("TemplatesExample.notSaveDocumentCaution")}</MessageBarTitle>
                  {t("TemplatesExample.notSaveDocumentCautionDescription")}
                </MessageBarBody>
                <MessageBarActions containerAction={
                  <Button appearance="transparent" icon={<DismissRegular />} onClick={() => setEdited(false)} />}
                > </MessageBarActions>
              </MessageBar>
            ))}
          </div>}
        {cautionPastUsers &&
          <div style={{ width: "100%", color: "black" }}>
            {intentsReset.map((intent) => (
              <MessageBar key={intent} intent={intent} >
                <MessageBarBody>
                  <MessageBarTitle> {t("TemplatesExample.uploadLogoOrSignature")}</MessageBarTitle>
                  {t("TemplatesExample.cautionExistingCustomers1")}
                </MessageBarBody>
                <MessageBarActions containerAction={
                  <Button appearance="transparent" icon={<DismissRegular />} onClick={() => setCautionPastUser(false)} />}
                > </MessageBarActions>
              </MessageBar>
            ))}
          </div>
        }
        <Toaster toasterId={toasterId} />
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: "2%", marginBottom: "2%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div styles={{ width: "100%", color: "black" }}>
              <Label style={{ color: "black", fontSize: "20px", fontWeight: "bold" }}> {t(`templateTinyPreview.${data.selectedTemplateConfirm?.type}`)}  </Label>
            </div>
            <div style={{ width: "100%", color: "black", marginTop: "2px", textAlign: "center", justifyContent: "center", display: "flex" }}>
              <div>
                <Label style={{ color: "black", fontSize: "20px", fontWeight: "bold", display: "flex", textAlign: "center", }}>
                  {ExtractNumberNameFromStyleId(selectedTemplateConfirm?.styleId ?? data.selectedTemplateConfirm.styleId, t)}
                </Label>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            height: "auto",
            alignItems: "center",
            position: "relative",
            width: "100%",
            padding: "5%",
            paddingTop: "0%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "30px",
              marginBottom: "1%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Menu >
                {/* <MenuTrigger disableButtonEnhancement >
                  {(MenuButtonProps) => (
                    <SplitButton
                      icon={<SaveRegular />}
                      menuButton={MenuButtonProps}
                      appearance="primary"
                      style={{ marginRight: "10px", maxWidth: "290px", }}
                      primaryActionButton={primaryActionButtonProps}
                      disabled={isLoading}

                    >
                      {t("templatesSelector.savedTemplateToAllDocuments")}
                    </SplitButton>
                  )}
                </MenuTrigger> 
                //Use when feature save all done
                */}

                <MenuTrigger disableButtonEnhancement >
                  {(MenuButtonProps) => (
                    <MenuButton
                      icon={<SaveRegular />}
                      menuButton={MenuButtonProps}
                      appearance="primary"
                      style={{ marginRight: "10px", maxWidth: "290px", minWidth: "255px" }}
                      // primaryActionButton={primaryActionButtonProps}
                      disabled={isLoading}
                      onClick={() => {
                        setOpen(true);
                        setChooseFix(selectedTemplateConfirm.type);
                      }}
                      menuIcon={null}
                    >
                      {t("templatesSelector.savedTemplateForThisDocuments")}
                    </MenuButton>
                  )}
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setOpenSecond(true);
                        setChooseFix("all");
                      }}
                    >
                      {t("templatesSelector.savedTemplateToAllDocuments")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpen(true);
                        setChooseFix(selectedTemplateConfirm.type);
                      }}
                    >
                      {t("templatesSelector.savedTemplateForThisDocuments")}
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              <Dialog
                modalType="modal"
                open={openSecond}
                onOpenChange={(event, data) => {
                  setOpenSecond(data.open);
                }}
              >
                <DialogSurface className="fluentui-dialog">
                  <DialogBody className="dialog-body">
                    <DialogTitle>
                      {" "}
                      {t("templatesSelector.confirmTemplate")}
                    </DialogTitle>
                    <DialogContent>
                      {" "}
                      {t("templatesSelector.selectedDocument1")}
                      {t("templatesSelector.selectedDocument2")}{" "}
                      {t("templatesSelector.selectedDocument3")}
                      <br />
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button
                          appearance="primary"
                          className="button-dialog"
                          onClick={() =>
                            handleConfirmTemplate(
                              selectedTemplateConfirm.styleId,
                              chooseFix
                            )
                          }
                        >
                          {t("templatesSelector.confirm")}
                        </Button>
                      </DialogTrigger>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" className="button-dialog">
                          {t("templatesSelector.cancel")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>

              <Dialog
                modalType="modal"
                open={open}
                onOpenChange={(event, data) => {
                  setOpen(data.open);
                }}
              >
                <DialogSurface className="fluentui-dialog">
                  <DialogBody className="dialog-body">
                    <DialogTitle>
                      {" "}
                      {t("templatesSelector.confirmTemplate")}
                    </DialogTitle>
                    <DialogContent>
                      {t("templatesSelector.areYouSure")}{" "}
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button
                          appearance="primary"
                          className="button-dialog"
                          onClick={() =>
                            handleConfirmTemplate(
                              selectedTemplateConfirm.styleId,
                              chooseFix
                            )
                          }
                        >
                          {t("templatesSelector.confirm")}
                        </Button>
                      </DialogTrigger>

                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" className="button-dialog">
                          {t("templatesSelector.cancel")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>

              <MenuButton
                appearance="secendary"
                icon={<ArrowHookUpLeftRegular />}
                menuIcon={null}
                onClick={handleBack}
                style={{ minWidth: "145px" }}
                disabled={isLoading}
              >
                {t("TemplatesExample.back")}
              </MenuButton>
            </div>
          </div>
          <Divider style={{ width: "90%" }} />
          <div style={{
            display: "flex",
            width: "100%",
            height: "30px",
            marginTop: "1%",
            marginBottom: "2%",
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
          }}>
            {visibleTemplates?.length !== 1 &&
              <div style={{ display: "flex", marginTop: "10px", position: "absolute", left: "5%" }}>

                {!isLoading ?
                  <>
                    <label style={{ fontSize: "14px", color: "black", alignContent: "center", minWidth: "50px" }}>{t("TemplatesExample.pickColor")}</label>
                    <div>
                      <Switch
                        defaultChecked={isEnabled}
                        checked={isEnabled ? true : false}
                        onClick={() => {
                          setIsEnabled((prevState) => !prevState)
                        }}
                        style={{
                          color: "#16263D",

                        }}

                      />
                    </div>
                  </>
                  :

                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        marginTop: "0px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      <div style={{ marginRight: "10px", marginTop: "5px" }}>
                        <Spinner size="tiny" label={t("TemplatesExample.loading")}></Spinner>
                      </div>

                    </div>
                  </div>
                }
              </div>
            }
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <MenuButton
                  appearance="secendary"
                  icon={<ArrowUploadRegular />}
                  menuIcon={null}
                  disabled={isLoading}
                  onClick={() => {
                    console.log("data.selectedTemplateConfirm style Id", selectedTemplateConfirm.styleId);
                    history.push({
                      pathname: "/templates/example/upload",
                      state: {
                        selectedTemplateConfirm: selectedTemplateConfirm,
                        selectedTemplate: data.selectedTemplate,
                        templates: data.templates,
                        searchId: data.searchId,
                        nameTemplate: data.nameTemplate,
                        selectedValue: data.selectedValue,
                        picture: data.picture,
                        allTemplates: data.allTemplates,
                        companyLogo: companyLogo,
                        companySignature: companySignature,
                        currentCardNumber: data.currentCardNumber,
                      }
                    })
                  }}
                  style={{ marginRight: "10px", maxWidth: "300px", minWidth: "255px" }}
                >
                  {t("TemplatesExample.uploadLogoOrSignature")}
                </MenuButton>
              </div>

              <div>
                <MenuButton
                  appearance="secendary"
                  icon={<ArrowClockwiseDashesRegular />}

                  menuIcon={null}
                  onClick={() => {
                    setOpenResetFirst(true);
                    setChooseFix(selectedTemplateConfirm.type);
                  }}
                  style={{ minWidth: "145px" }}
                  disabled={isLoading}
                >
                  {t("TemplatesExample.resetTemplate")}
                </MenuButton>
              </div>
              {/* <MenuPopover>
                  <MenuList>

                    <MenuItem
                      onClick={() => {
                        setOpenResetFirst(true);
                        setChooseFix(selectedTemplateConfirm.type);
                      }}
                  
                    >
                      {t("TemplatesExample.resetSpecificThisTemplates")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenResetSecond(true);
                        setChooseFix("all");

                      }}
                    
                    >
                      {t("TemplatesExample.resetAllTemplates")}
                    </MenuItem>
                  </MenuList>
                </MenuPopover> */}

              {/* <Dialog
                modalType="modal"
                open={openResetSecond}
                onOpenChange={(event, data) => {
                  setOpenResetSecond(data.open);
                }}
              >
                <DialogSurface className="fluentui-dialog">
                  <DialogBody className="dialog-body">
                    <DialogTitle>
                      {" "}
                      {t("TemplatesExample.confirmToReset")}
                    </DialogTitle>
                    <DialogContent>
                      {" "}
                    {t("TemplatesExample.everyTemplatesWillChanged")}
                      <br />
                    
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button
                          appearance="primary"
                          className="button-dialog"
                      
                          onClick={() =>
                            handleConfirmReset(
                              selectedTemplateConfirm.styleId,
                              chooseFix
                            )
                          }
                        >
                          {t("templatesSelector.confirm")}
                        </Button>
                      </DialogTrigger>

                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" className="button-dialog">
                          {t("templatesSelector.cancel")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog> */}

              <Dialog
                modalType="modal"
                open={openResetFirst}
                onOpenChange={(event, data) => {
                  setOpenResetFirst(data.open);
                }}
              >
                <DialogSurface className="fluentui-dialog">
                  <DialogBody className="dialog-body">
                    <DialogTitle>
                      {" "}
                      {t("TemplatesExample.confirmToReset")}
                    </DialogTitle>
                    <DialogContent>
                      {t("TemplatesExample.PleaseConfirmToResetTemplates")}
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button
                          appearance="primary"
                          className="button-dialog"
                          onClick={() =>
                            handleConfirmReset(
                              selectedTemplateConfirm.styleId,
                              chooseFix
                            )
                          }
                        >
                          {t("templatesSelector.confirm")}
                        </Button>
                      </DialogTrigger>

                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" className="button-dialog">
                          {t("templatesSelector.cancel")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            </div>

          </div>

          {isEnabled && <>
            {visibleTemplates.length !== 1 &&
              <div
                className={classes.controlCardSlide}
              >
                <div style={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  alignSelf: "center",
                  justifyContent: "center",
                  gap: "1%",
                  top: "0%"
                }}>
                  {hasPrev && (
                    <ArrowCircleLeftRegular onClick={handlePrevCard} color="#0078D4" style={{ cursor: "pointer", position: "absolute", left: "2%", top: "40%", fontSize: "40px", transition: "transform 0.3s ease" }}
                      className="move-left-on-hover"
                    />
                  )}
                  {visibleTemplates.map((template) => (
                    <div
                      className={`${classes.root} 
                      ${selectedTemplateConfirm.styleId === template.styleId ? classes.selected : ""} 
                      ${hoveredTemplateId === template.styleId ? classes.hover : ""}`}
                      onClick={() => {
                        pickThisTemplate(template)

                      }
                      }
                      key={template.styleId}
                      onMouseEnter={() => setHoveredTemplateId(template.styleId)}
                      onMouseLeave={() => setHoveredTemplateId(null)}
                    >
                      <CardPreview className={classes.slideCard}>
                        <img
                          src={`${template.imageData}`}
                          alt="Preview of a Word document"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </CardPreview>

                    </div>
                  ))}
                  {hasNext && (
                    <ArrowCircleRightRegular onClick={handleNextCard} color="#0078D4" style={{ cursor: "pointer", position: "absolute", right: "2%", top: "40%", fontSize: "40px" }}
                      className="move-right-on-hover"
                    />
                  )}
                </div>

              </div>}
          </>
          }
          {isLoading ?
            <Spinner size="medium" label={textSpinner} style={{ height: "300px", width: "100%" }}></Spinner> :
            <>

              <PdfAllPages pdf={responseExampleFromServer} />
            </>
          }
        </div>
      </TemplatesSelectStructure>
    </>
  )
}

export default TemplatesExample
