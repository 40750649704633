import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Label } from "@fluentui/react-components";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Spinner
} from "@fluentui/react-components";
import { ArrowHookUpLeftRegular, SaveRegular, ArrowClockwiseDashesRegular } from "@fluentui/react-icons";
import {
  useId,
  Link,
  Button,
  Toaster,
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  MenuButton
} from "@fluentui/react-components";

import PdfAllPages from "../PdfAllPages";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";
import { ExtractNumberNameFromStyleId } from "../ExtractNumberNameFromStyleId";
const API_URL = process.env.REACT_APP_API_URL

const PreviewTemplate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const data = location.state;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [pdf] = useState(location.state);
  const companyId = sessionStorage.getItem('companyId') || '';

  const handleSave = async (htmlContent) => {

    try {
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }
      // const response = await axios.put(
      //   `http://localhost:7071/api/templates/html?documentType=${data.selectedValue}&templateNumber=${data.styleId}`,
      //   htmlContent,
      //   {
      //     headers: {
      //       "cid": companyId,
      //       "Content-Type": "text/html",
      //       Authorization: "Bearer " + tokenResponse.accessToken,
      //     }
      //   }
      // );

      const response = await axios.put(
        `${API_URL}/templates/html?documentType=${data.selectedValue}&templateNumber=${data.styleId}`,
        htmlContent,
        {
          headers: {
            "cid": companyId,
            "Content-Type": "text/html",
            Authorization: "Bearer " + tokenResponse.accessToken,
          }
        }
      );

      TemplateConfirm();
      console.log(response.data)
      history.push({
        pathname: "/templates",
        state: {
          triggerToaster: true
        }
      })
    } catch (error) {
      console.error("Error saving HTML content:", error);
    }
  };

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const TemplateConfirm = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("templatesSelector.savedSuccess")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );


  const [pdfForPreview, setPdfForPreview] = useState(null)
  const [open, setOpen] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const EditReset = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("templatesSelector.resetTemplateAlready")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );

  const EditUnsuccess = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("templatesSelector.failToSave")}
        </ToastTitle>
      </Toast>,
      { intent: "error" }
    );



  const handleReset = async (templateNumber, documentType, formData) => {
    try {
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.post(
        `${API_URL}/templates/reset?templateNumber=${templateNumber}&documentType=${documentType}`,
        formData,
        {
          headers: {
            "cid": companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          }
        }
      )
      console.log("response Reset ", response.data);
      EditReset();
      if (response.data) {
        PreviewCurrentTemplates(data.styleId, data.selectedValue)
      }

    } catch (error) {
      console.error("Error Reset HTML content:", error);
      EditUnsuccess();
      // Handle error: show error message to the user or retry saving
    }
  };


  useEffect(() => {
    setPdfForPreview(pdf.responseFromServer)
    // eslint-disable-next-line
  }, [])

  const PreviewCurrentTemplates = async (templateNumber, documentType) => {
    try {
      setIsLoading(true)
      console.log("documentType Right now ", documentType);

      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.post(
        `${API_URL}/templates/pdf/preview?documentType=${documentType}&templateNumber=${templateNumber}`, {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "cid": companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
          responseType: 'blob'
        } // Set responseType to blob to receive blob data
      );

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfURL = URL.createObjectURL(pdfBlob);
      console.log("response ", response.data);
      setPdfForPreview(pdfURL)
      setIsLoading(false)

    } catch (error) {
      // Handle network error or other exceptions
      console.error("Error:", error);
    }
  };

  return (
    <section className="main-pages-tiny">
      <div
        className="control-main-pages">
        <Toaster toasterId={toasterId} />
        <h2 styles={{ width: "100%", marginTop: "5%", color: "black" }}>  <Label style={{ color: "black", fontSize: "20px" }}> {t(`templateTinyPreview.example`)}  {t(`templateTinyPreview.${pdf.selectedValue}`)} {ExtractNumberNameFromStyleId(pdf.confirmedTemplateStyleId, t)}</Label> </h2>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "30px",
            marginBottom: "2%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {data.backUrl === '/templates/advance-edit' ?
              <MenuButton onClick={() => setOpen(true)} style={{ marginRight: "10px" }} appearance="primary" menuIcon={null} icon={<SaveRegular />}>
                {t("templateTinyPreview.save")}
              </MenuButton> :
              ""}
            <Dialog
              modalType="modal"
              open={open}
              onOpenChange={(event, data) => {
                setOpen(data.open);
              }}
            >
              <DialogSurface>
                <DialogBody>
                  <DialogTitle> {t("templatesSelector.title")}</DialogTitle>
                  <DialogContent>
                    {t("templateTinyPreview.areYouSure")}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        onClick={() => {
                          handleSave(
                            data.htmlContent,
                            data.styleId,
                            data.selectedValue
                          );
                        }}
                        appearance="primary"
                      >
                        {t("templateTinyPreview.save")}
                      </Button>
                    </DialogTrigger>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary">
                        {t("templateTinyPreview.cancel")}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
            <div>
              <MenuButton
                appearance={data.backUrl !== '/templates/advance-edit' ? "primary" : "secondary"}
                style={{ alignSelf: "center" }}
                icon={<ArrowHookUpLeftRegular />}
                menuIcon={null}
                onClick={() => {
                  history.push({
                    pathname: data.backUrl,
                    state: {
                      name: data.name,
                      styleId: data.styleId,
                      selectedValue: data.selectedValue,
                      confirmedTemplateStyleId: data.confirmedTemplateStyleId,
                    },
                  });
                }}

              >
                {t("templatesSelector.back")}
              </MenuButton>
              {data.backUrl !== '/templates/advance-edit' && <>
                <MenuButton
                  appearance="secondary"
                  style={{ alignSelf: "center", marginLeft: "10px" }}
                  icon={<ArrowClockwiseDashesRegular />}
                  menuIcon={null}
                  onClick={() => {
                    setOpenSecond(true)
                  }}
                >
                  {t("templatesSelector.resetDoc")}
                </MenuButton>
                <Dialog
                  modalType="modal"
                  open={openSecond}
                  onOpenChange={(event, data) => {
                    setOpenSecond(data.open);
                  }}
                  onClick={() => setOpenSecond(true)}
                >
                  <DialogSurface className="fluentui-dialog">
                    <DialogBody className="dialog-body">
                      <DialogTitle>
                        {" "}
                        {t("templatesSelector.titleReset")}
                      </DialogTitle>
                      <DialogContent>
                        {t("templatesSelector.areYouSureToReset")}
                      </DialogContent>
                      <DialogActions>
                        <DialogTrigger>
                          <Button
                            appearance="primary"
                            className="button-dialog"
                            onClick={() => {
                              const formData = new FormData();
                              formData.append("logo", null);
                              formData.append("signature", null);
                              handleReset(data.styleId, data.selectedValue, formData)
                            }
                            }
                          >
                            {t("templatesSelector.confirm")}
                          </Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="secondary" className="button-dialog">
                            {t("templatesSelector.cancel")}
                          </Button>
                        </DialogTrigger>
                      </DialogActions>
                    </DialogBody>
                  </DialogSurface>
                </Dialog>
              </>
              }
            </div>
          </div>
        </div>
        {isLoading ?
          <div style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spinner size="extra-large" label={t("templatesSelector.loading")}></Spinner>
          </div> :
          <PdfAllPages pdf={pdfForPreview} />}
      </div>
    </section>


  );
};

export default PreviewTemplate;
