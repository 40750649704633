import * as React from "react";
import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  useId,
  Label
} from "@fluentui/react-components";

import {
  documentOptionswithTextEn,
  documentOptionswithTextTh,
} from "./InvoiceType";

import {
  englishDocumentOptions,
  thaiDocumentOptions,
} from "./InvoiceType";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "250px",
  },
});

export const Default = ({
  onClick,
  selectedValue,
}) => {
  const dropdownId = useId("dropdown-default");
  const { t, i18n } = useTranslation();

  const options =
    i18n.language === "th"
      ? documentOptionswithTextTh
      : documentOptionswithTextEn;

  const styles = useStyles();

  const getDocumentTypeText = (_selectedValue) => {
    if (i18n.language === "th") {
      return thaiDocumentOptions.find(
        (documentoptions) => documentoptions.filter === _selectedValue
      ).title;
    }

    return englishDocumentOptions.find(
      (documentoptions) => documentoptions.filter === _selectedValue
    ).title;
  };

  return (
    <div className={styles.root}>
      <Label weight="semibold" size="large">{t("templatesSelector.documentType")}</Label>
      <Dropdown
        defaultValue={t("templateTinyPreview.receipt-taxinvoice")}
        aria-labelledby={dropdownId}
        placeholder={options.title}
        value={getDocumentTypeText(selectedValue, i18n.language)}
        style={{ width: "un-set", minWidth: "300px", borderBlockWidth: "2px", borderBottomRightRadius: "2px" }}
      >
        {options.map((option) => (
          <Option
            key={option.key}
            onClick={() => onClick(option.key)}
            text={option.title}
          >
            {option.title}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};
