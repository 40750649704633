export const documentOptions = [
  "receipt-taxinvoice",
  "receipt-taxinvoice-abb",
  "receipt",
  "invoice-taxinvoice",
  "deliveryorder-taxinvoice",
  "taxinvoice",
  "debitNote",
  "creditNote",
  "quotation",
  "invoice",
  "billing-note",
  "deliveryorder",
  // Add more options as needed
];

export const getDocumentTypeText = (_selectedValue, language) => {
  if (language === "thai" && thaiDocumentOptions) {
    return (
      thaiDocumentOptions.find(
        (documentoptions) => documentoptions?.filter === _selectedValue
      )?.title || "Unknown Title" // not found return "Unknown Title"
    );
  }

  // checking englishDocumentOptions that have value?
  if (englishDocumentOptions) {
    return (
      englishDocumentOptions.find(
        (documentoptions) => documentoptions?.filter === _selectedValue
      )?.title || "Unknown Title" // not found return  "Unknown Title"
    );
  }

  // In case language is not "thai" or no value in englishDocumentOptions
  return "Unknown Title";
};


export const documentOptionswithTextTh = [
  {
    id: "010",
    key: "receipt-taxinvoice",
    title: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
  },
  {
    id: "020",
    key: "receipt-taxinvoice-abb",
    title: "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ",
  },
  {
    id: "030",
    key: "receipt",
    title: "ใบเสร็จรับเงิน",
  },
  { id: "040", key: "invoice-taxinvoice", title: "ใบแจ้งหนี้/ใบกำกับภาษี" },
  { id: "050", key: "deliveryorder-taxinvoice", title: "ใบส่งของ/ใบกำกับภาษี" },
  { id: "060", key: "taxinvoice", title: "ใบกำกับภาษี " },
  { id: "070", key: "debit-note", title: "ใบเพิ่มหนี้" },
  { id: "080", key: "credit-note", title: "ใบลดหนี้" },
  { id: "090", key: "quotation", title: "ใบเสนอราคา" },
  { id: "100", key: "invoice", title: "ใบแจ้งหนี้ " },
  { id: "110", key: "billing-note", title: "ใบวางบิล " },
  { id: "120", key: "deliveryorder", title: "ใบส่งของ" },
  // Add more options as needed
];

export const documentOptionswithTextEn = [
  {
    id: "010",
    key: "receipt-taxinvoice",
    title: "Receipt/Tax Invoice",
  },
  {
    id: "020",
    key: "receipt-taxinvoice-abb",
    title: "Reciept/Tax Invoice Abb",
  },
  {
    id: "030",
    key: "receipt",
    title: "Reciept",
  },
  { id: "040", key: "invoice-taxinvoice", title: "Invoice/Tax Invoice" },
  {
    id: "050",
    key: "deliveryorder-taxinvoice",
    title: "Delivery Order/Tax Invoice",
  },
  { id: "060", key: "taxinvoice", title: "Tax Invoice" },
  { id: "070", key: "debit-note", title: "Debit Note" },
  { id: "080", key: "credit-note", title: "Credit Note" },
  { id: "090", key: "quotation", title: "Qoutation" },
  { id: "100", key: "invoice", title: "Invoice" },
  { id: "110", key: "billing-note", title: "Billing Note" },
  { id: "120", key: "deliveryorder", title: "Delivery Order" },
  // Add more options as needed
];


export const getDocumentOptions = (language) => {
  return language === "thai" ? thaiDocumentOptions : englishDocumentOptions;
};

export const englishDocumentOptions = [
  {
    id: "RECEIPT-TAXINVOICE",
    title: "Receipt/Tax Invoice",
    filter: "receipt-taxinvoice",
  },
  {
    id: "RECEIPT-TAXINVOICE-ABB",
    title: "Receipt/Tax Invoice Abb",
    filter: "receipt-taxinvoice-abb",
  },
  { id: "RECEIPT", title: "Receipt", filter: "receipt" },
  {
    id: "INVOICE-TAXINVOICE",
    title: "Invoice/Tax Invoice",
    filter: "invoice-taxinvoice",
  },
  {
    id: "DELIVERYORDER-TAXINVOICE",
    title: "Delivery Order/Tax Invoice",
    filter: "deliveryorder-taxinvoice",
  },
  {
    id: "TAXINVOICE",
    title: "Tax Invoice",
    filter: "taxinvoice",
  },
  { id: "DEBIT-NOTE", title: "Debit Note", filter: "debit-note" },
  { id: "CREDIT-NOTE", title: "Credit Note", filter: "credit-note" },
  { id: "QUOTATION", title: "Quotation", filter: "quotation" },
  { id: "INVOICE", title: "Invoice", filter: "invoice" },
  { id: "BILLING-NOTE", title: "Billing Note", filter: "billing-note" },
  { id: "DELIVERYORDER", title: "Delivery Order", filter: "deliveryorder" },
];

export const thaiDocumentOptions = [
  {
    id: "RECEIPT-TAXINVOICE",
    title: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    filter: "receipt-taxinvoice",
  },
  {
    id: "RECEIPT-TAXINVOICE-ABB",
    title: "ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ ",
    filter: "receipt-taxinvoice-abb",
  },
  { id: "RECEIPT", title: "ใบเสร็จรับเงิน", filter: "receipt" },
  {
    id: "INVOICE-TAXINVOICE",
    title: "ใบแจ้งหนี้/ใบกำกับภาษี",
    filter: "invoice-taxinvoice",
  },
  {
    id: "DELIVERYORDER-TAXINVOICE",
    title: "ใบส่งของ/ใบกำกับภาษี",
    filter: "deliveryorder-taxinvoice",
  },
  { id: "TAXINVOICE", title: "ใบกำกับภาษี", filter: "taxinvoice" },
  { id: "DEBIT-NOTE", title: "ใบเพิ่มหนี้ ", filter: "debit-note" },
  { id: "CREDIT-NOTE", title: "ใบลดหนี้ ", filter: "credit-note" },
  { id: "QUOTATION", title: "ใบเสนอราคา ", filter: "quotation" },
  { id: "INVOICE", title: "ใบแจ้งหนีั ", filter: "invoice" },
  { id: "BILLING-NOTE", title: "ใบวางบิล", filter: "billing-note" },
  { id: "DELIVERYORDER", title: "ใบส่งของ", filter: "deliveryorder" },
];
