import * as React from "react";
import { useState, useEffect } from "react";
import {
  makeStyles,
  shorthands,
  Text,
  MenuButton,
  MenuTrigger,
  MenuPopover,
  MenuList,
  Menu,
  MenuItemRadio,
} from "@fluentui/react-components";
// import "./SelectTemplateApp.css";

import {
  documentOptionswithTextEn,
  documentOptionswithTextTh,
} from "./PageNumber";

import { useTranslation } from "react-i18next";
import { TextNumberListLtrFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    minWidth: "50px",
  },
});

export const DropDownForContactsList = ({
  onClick,
  selectedValue,
  setSelectedOptions,
  setSelectedOptionsValuePerPage,
  isLoadComplete,
  isSearchComplete,
  checkedValuesDocument,
  onChangeDocuments,
}) => {
  const { t, i18n } = useTranslation();

  const options = i18n.language === "th"
    ? documentOptionswithTextTh
    : documentOptionswithTextEn;

  const styles = useStyles();

  const [dropdownOption, setDropdownOption] = useState([]);
  useEffect(() => {
    if (options.length > 0) {
      setDropdownOption(options);
      setSelectedOptions([options[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, options]);

  useEffect(() => {
    if (dropdownOption.length > 0) {
      // Convert selectedValue to an array index
      const index = selectedValue / 10 - 1;

      if (index >= 0 && index < dropdownOption.length) {
        setSelectedOptionsValuePerPage([dropdownOption[index]?.key]);
      } else {
        console.error("Index is out of bounds:", index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, dropdownOption]);

  return (
    <div className={styles.root}>
      <div style={{ display: "flex", minWidth: "50px" }}>

        <Menu
          checkedValues={checkedValuesDocument}
          onCheckedValueChange={onChangeDocuments}
          closeOnScroll={true}
          disabled={!isLoadComplete || !isSearchComplete}

        >
          <MenuTrigger >
            <MenuButton
              icon={<TextNumberListLtrFilled />}
              disabled={!isLoadComplete || !isSearchComplete}
              style={{ fontWeight: "normal" }}
            >
              {selectedValue} {t("requestFormContactsList.documents")}
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList  >
              {options.map((option, index) => (
                <MenuItemRadio
                  key={index + 1}
                  value={index + 1}
                  onClick={() => onClick(option.value)}
                  onSelect={selectedValue}
                  icon={<TextNumberListLtrFilled />}
                  name="documents"
                  disabled={!isLoadComplete || !isSearchComplete}
                >
                  <Text style={{ fontWeight: "normal" }}>
                    {" "}
                    {option.value} {t("requestFormContactsList.documents")}
                  </Text>
                </MenuItemRadio>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};
