import { Label } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { PresenceBadge, Checkbox } from "@fluentui/react-components";
import { shorthands, Tab, TabList } from "@fluentui/react-components";
import {
  DocumentAdd24Regular,
  Signature24Regular,
} from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import TemplatesSelectStructure from "../../TemplatesSelectStructure.js";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,

} from "@fluentui/react-components";
import { ArrowHookUpLeftRegular, ArrowRightFilled } from "@fluentui/react-icons";
import {
  MenuButton,
} from "@fluentui/react-components";
import { useLocation, useHistory } from "react-router-dom";
import {
  getTheme,
} from '@fluentui/react';
const useStyles = makeStyles({
  root: {
    color: "0 0 10px rgba(0, 224, 343, 1)",
    width: 50,
    height: 50,
    backgroundColor: "0 0 10px rgba(0, 224, 343, 1)",
  },
  selected: {
    border: "blue",
    boxShadow: "0 0 10px rgba(0, 224, 343, 1)",
    width: 80,
    height: 80,
  },
  hover: {
    backgroundColor: "#ccffff",
    border: "500px solid black",
    boxShadow: "0 0 20px rgba(31, 156, 238)",
  },
  TabPage: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "20px"),
    rowGap: "20px",
  },
});

const UploadPage = ({ TabListProps }) => {
  const [logo, setLogo] = useState(null);
  const [signature, setSignature] = useState(null);
  const [activePage, setActivePage] = useState("logo");
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()
  const [companyLogo, setCompanyLogo] = useState(null)
  const [companySignature, setCompanySignature] = useState(null)
  const data = location.state

  useEffect(() => {
    if (data?.companyLogo !== null || data?.companySignature !== null) {
      if (data.companyLogo !== null) {
        setLogo(data.companyLogo)
        setCompanyLogo(data.companyLogo)
        setIsLogoUploaded(true)
      }
      if (data.companySignature !== null) {
        setSignature(data.companySignature)
        setCompanySignature(data.companySignature)
        setIsSignatureUploaded(true)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setLogo(data.companyLogo)
    setCompanyLogo(data.companyLogo)
    setSignature(data.companySignature)
    setCompanySignature(data.companySignature)
    // eslint-disable-next-line
  }, [])

  const theme = getTheme();
  const handleBack = () => {
    history.push({
      pathname: "/templates/example",
      state: {
        selectedTemplateConfirm: data.selectedTemplateConfirm,
        selectedTemplate: data.selectedTemplate,
        templates: data.templates,
        searchId: data.searchId,
        nameTemplate: data.nameTemplate,
        selectedValue: data.selectedValue,
        picture: data.picture,
        allTemplates: data.allTemplates,
        companyLogo: data.companyLogo,
        companySignature: data.companySignature,
        currentCardNumber: data.currentCardNumber
      },
    }
    )
  };

  const handleUpload = () => {
    history.push({
      pathname: "/templates/example",
      state: {
        selectedTemplateConfirm: data.selectedTemplateConfirm,
        selectedTemplate: data.selectedTemplate,
        templates: data.templates,
        searchId: data.searchId,
        nameTemplate: data.nameTemplate,
        selectedValue: data.selectedValue,
        picture: data.picture,
        allTemplates: data.allTemplates,
        companyLogo: companyLogo,
        companySignature: companySignature,
        currentCardNumber: data.currentCardNumber,
        blankUpload: true
      },
    }
    )
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileChange = async (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false); // Dialog visibility

  const maxFileSizeMb = 20;

  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/webp",
    "image/jpg"
  ];

  const handleFileUpload = (file, key) => {
    if (file) {
      // Check file type
      if (!allowedFileTypes.includes(file.type)) {
        setIsDialogOpen(true);
        return;
      }
      const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
      if (fileSizeMB > maxFileSizeMb) {
        setIsDialogOpen(true);
        return;
      }
    }

    if (file) {
      const isImage = file.type.startsWith("image/");
      if (isImage) {
        const updatedFile = new File([file], `${key}`, {
          type: file.type,
        });
        if (activePage === "logo") {
          setLogo(updatedFile);
          setCompanyLogo(updatedFile);
          setIsLogoUploaded(true);
          setIsChecked((prevChecked) => (prevChecked ? false : prevChecked));
        } else {
          setSignature(updatedFile);
          setCompanySignature(updatedFile);
          setActivePage("signature");
          setIsSignatureUploaded(true);
          setIsChecked((prevChecked) => (prevChecked ? false : prevChecked));
        }
      } else {
        alert("Please upload a valid image file.");
      }
    }
  };

  const handleRemoveImage = () => {
    if (activePage === "logo") {
      setLogo(null);
      setCompanyLogo(null)
      setIsChecked(false);
      setIsLogoUploaded(false);
    } else {
      setSignature(null);
      setCompanySignature(null)
      setIsChecked(false);
      setIsSignatureUploaded(false);
    }
  };

  const switchToLogoPage = () => {
    setActivePage("logo");
  };

  const switchToSignaturePage = () => {
    setActivePage("signature");
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeSignature = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const [isLogoUploaded, setIsLogoUploaded] = useState(false);
  const [isSignatureUploaded, setIsSignatureUploaded] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TemplatesSelectStructure>
        <div
          style={{
            marginTop: "2%",
            marginBottom: "2%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "30px",
              marginBottom: "2%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MenuButton
              disabled={
                (isLogoUploaded && !isChecked) ||
                (isSignatureUploaded && !isChecked)}
              onClick={() => {
                companyLogo === null && companySignature === null ?
                  handleUpload() : setOpen(true)
              }
              }
              style={{ marginRight: "10%", minWidth: "120px" }}
              appearance="primary"
              menuIcon={null} icon={<ArrowRightFilled />}>
              {t("uploadPage.next")}
            </MenuButton>


            <Dialog
              modalType="modal"
              open={open}
              onOpenChange={(event, data) => {
                setOpen(data.open);
              }}
            >
              <DialogSurface className="fluentui-dialog">
                <DialogBody className="dialog-body">
                  <DialogTitle>
                    {" "}
                    {t("uploadPage.confirmToUpload")}
                  </DialogTitle>
                  <DialogContent>

                    <p>{t("uploadPage.wouldYouLikeToUpload")}</p>

                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger>
                      <Button
                        appearance="primary"
                        className="button-dialog"
                        onClick={() =>
                          handleUpload()
                        }
                      >
                        {t("templatesSelector.confirm")}
                      </Button>
                    </DialogTrigger>

                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary" className="button-dialog">
                        {t("templatesSelector.cancel")}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>

            <MenuButton
              appearance="secendary"
              icon={<ArrowHookUpLeftRegular />}
              menuIcon={null}
              onClick={handleBack}
            >
              {t("uploadPage.back")}
            </MenuButton>
          </div>

        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: theme.effects.elevation4,
            minWidth: "600px",
            maxWidth: "800px",
            minHeight: "500px",
            width: "100%",
            height: "100%",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div
              className={classes.TabPage}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                height: "400px",

                position: "relative",
              }}
            >
              <Label
                style={{
                  margin: "10px 0",
                  color: "#000000",

                  width: "200px",
                  textAlign: "left",
                }}
              >
                <h2>{t("uploadPage.choiceforupload")}</h2>
              </Label>
              <TabList
                defaultSelectedValue={activePage}
                style={{
                  display: "flex",
                  flexDirection: "column",

                  position: "absolute",
                  left: "5px",
                  top: "100px",
                }}
                {...TabListProps}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        width: "136px",
                      }}
                    >
                      <Tab
                        iconProps={{ iconName: "ImageSearch" }}
                        onClick={switchToLogoPage}
                        value={"logo"}
                        isActive={activePage === "logo"}
                      >
                        {t("uploadPage.logoImage")}
                      </Tab>
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      {logo && <PresenceBadge />}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "136px",
                    }}
                  >
                    <Tab
                      size="large"
                      shape="square"
                      iconProps={{ iconName: "ImageSearch" }}
                      style={{
                        marginBottom: "10px",
                      }}
                      onClick={switchToSignaturePage}
                      value={"signature"}
                      isActive={activePage === "signature"}
                    >
                      {t("uploadPage.signatureImage")}
                    </Tab>
                  </div>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    {signature && <PresenceBadge />}
                  </div>
                </div>
              </TabList>
            </div>

            <div
              className="uploadside"
              style={{
                display: "flex",
                position: "relative",
                width: "600px",
                height: "100%",
                justifyContent: "center",
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  border: "2px dashed #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                  marginTop: "30px",
                  width: "90%",
                  height: "350px",
                  position: "relative",

                }}
              >
                <div
                  className="dragdropfield"
                  style={{
                    display: "flex",
                    flexDirection: "center",
                    justifyContent: "center",
                  }}
                >
                  {activePage === "logo" && (
                    <>
                      {logo && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={URL.createObjectURL(logo)}
                            alt="Logo"
                            style={{ height: "70px", marginBottom: "25px" }}
                          />
                          <Button onClick={handleRemoveImage}>
                            {t("uploadPage.removeLogo")}
                          </Button>
                        </div>
                      )}
                      {!logo && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              padding: "20px",
                              color: "#555",
                            }}
                          >
                            <DocumentAdd24Regular
                              style={{
                                fontSize: "40px",
                                width: "50px",
                                height: "50px",
                              }}
                            />
                            <p
                              style={{
                                margin: "10px 0",
                                marginBottom: "0px",

                                fontSize: "15px",
                              }}
                            >
                              {t("uploadPage.dragDropOrClickAdd")}

                            </p>
                            <p
                              style={{
                                margin: "10px 0",
                                marginTop: "2px",
                                marginBottom: "25px",
                                fontSize: "15px",
                              }}
                            >
                              {t("uploadPage.fileThatAllowed")}
                            </p>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              id="fileInput"
                            />
                            <Button
                              appearance="primary"
                              iconProps={{ iconName: "Document" }}
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                              styles={{ root: { marginBottom: "10px" } }}
                            >
                              {t("uploadPage.addLogo")}
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {activePage === "signature" && (
                    <>
                      {signature && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",

                            alignItems: "center",
                          }}
                        >
                          <img
                            src={URL.createObjectURL(signature)}
                            alt="Signature"
                            style={{ height: "70px", marginBottom: "25px" }}
                          />
                          <Button
                            style={{ marginTop: "5px" }}
                            onClick={handleRemoveImage}
                          >
                            {t("uploadPage.removeSignature")}
                          </Button>
                        </div>
                      )}
                      {!signature && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              padding: "20px",
                              color: "#555",
                            }}
                          >
                            <Signature24Regular
                              style={{
                                fontSize: "40px",
                                width: "50px",
                                height: "50px",
                              }}
                            />
                            <p
                              style={{
                                margin: "10px 0",
                                marginBottom: "0px",
                                fontSize: "15px",
                              }}
                            >
                              {t("uploadPage.dragDropOrClickAdd")}
                            </p>
                            <p
                              style={{
                                margin: "10px 0",
                                marginTop: "2px",
                                marginBottom: "25px",
                                fontSize: "15px",
                              }}
                            >
                              {t("uploadPage.fileThatAllowed")}
                            </p>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              id="fileInput"
                            />
                            <Button
                              iconProps={{ iconName: "Document" }}
                              appearance="primary"
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                              styles={{ root: { marginBottom: "10px" } }}
                            >
                              {t("uploadPage.addSignature")}
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {signature || logo ? (
                <div
                  style={{
                    width: "500px",
                    height: "100px",
                    fontSize: "12PX",
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    bottom: "-40px",
                    color: "black"
                  }}
                >
                  <div
                    style={{ position: "absolute", left: "-20px", top: "10px" }}
                  >
                    <Checkbox
                      onChange={handleCheckboxChangeSignature}
                      checked={isChecked}
                    />
                  </div>
                  <p
                    style={{
                      textAlign: "left",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    {" "}
                    {t("uploadPage.addLogoOrSignature1")}
                    <a
                      target="_blank"
                      href=" https://www.leceipt.com/terms-conditions"
                    >
                      {t("uploadPage.termsAndConditions")}
                    </a>
                    {t("uploadPage.includes")}
                    <a
                      target="_blank"
                      href=" https://www.leceipt.com/terms-conditions"
                    >
                      {t("uploadPage.privacyPolicy")}
                    </a>{" "}
                    {t("uploadPage.addLogoOrSignature2")}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Dialog
            modalType="modal"
            open={isDialogOpen}
            onOpenChange={(event, data) => {
              setIsDialogOpen(data.open);
            }}

          >
            <DialogSurface className="fluentui-dialog">
              <DialogBody className="dialog-body">
                <DialogTitle>
                  {" "}
                  {t("uploadPage.uploadFail")}
                </DialogTitle>
                <DialogContent style={{ maxWidth: "90%" }}>
                  {t("uploadPage.yourImageWeNotAllow")}
                  {t("uploadPage.pleaseAddFileTypeThatAllow")} <br />
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary" className="button-dialog">
                      {t("uploadPage.acknowledge")}
                    </Button>
                  </DialogTrigger>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        </div>

      </TemplatesSelectStructure>
    </>
  );
};

export default UploadPage;
