import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import {
  CardPreview,
  MenuItem,
  MenuList,
  MenuTrigger,
  MenuPopover,
  Menu,
  MenuButton,
  Spinner,
} from "@fluentui/react-components";
import {
  MoreHorizontal24Regular,
  Settings24Filled,
  ImageSearch24Filled
} from "@fluentui/react-icons";
import { makeStyles } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";
const API_URL = process.env.REACT_APP_API_URL
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "280px",
    height: "395px",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#e0e0eb",
    padding: "3.5%"
  },

  selected: {
    backgroundColor: "#e0e0eb",
  },
  hover: {
    backgroundColor: "#efeff5",
  },

  buttonContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    top: "0px",
    right: "1px",
    zIndex: "1001"
  },
  hoverEffect: {
    transform: "revert",
  },
  nothoverAdvance: {
    fontSize: "15px",
  },
  hoverAdvance: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonContainerNewWay: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    marginBottom: "-190px",
    bottom: "115px",
    left: "85px",
    backgroundColor: "yellow"
  },
});

const CurrentCard = ({
  templates,
  selectedValue,
  history,
  confirmedTemplateStyleId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [isOpen, setIsOpen] = useState(false);
  const backUrl = '/templates'
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const [isLoading, setIsLoading] = useState(false);

  const companyId = sessionStorage.getItem('companyId') || '';
  const PreviewCurrentTemplates = async (templateNumber, documentType) => {
    try {
      let tokenResponse;
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...silentRequest,
          account: account,
        });
      } catch (error) {
        console.error("Error acquiring token: ", error);
        instance.acquireTokenRedirect(silentRequest);
        return;
      }

      const response = await axios.post(
        `${API_URL}/templates/pdf/preview?documentType=${documentType}&templateNumber=${templateNumber}`, {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "cid": companyId,
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
          responseType: 'blob'
        } // Set responseType to blob to receive blob data
      );

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfURL = URL.createObjectURL(pdfBlob);

      if (response.data) {
        history.replace({
          pathname: "/templates/preview",
          state: {
            name: selectedValue,
            styleId: confirmedTemplateStyleId,
            selectedValue: selectedValue,
            confirmedTemplateStyleId: confirmedTemplateStyleId,
            responseFromServer: pdfURL,
            backUrl: backUrl,
          },
        });
      }
    } catch (error) {
      // Handle network error or other exceptions
      console.error("Error:", error);
    }
  };

  const handlePreview = async (templatesNumber, documentType) => {
    try {
      setIsLoading(true);
      await PreviewCurrentTemplates(templatesNumber, documentType);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);

    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        alignItems: "center",
        borderRadius: "0.375rem",
      }}
      className={`${classes.root} `}
      onClick={() => setIsOpen(true)}
    >
      <div className={classes.buttonContainer}>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            {isOpen ? (
              <div
                style={{
                  backgroundColor: "white",
                  border: "0.5px  black",
                  width: "25px",
                  height: "20px",
                  borderRadius: "2px",
                }}
              >
                {" "}
                <MoreHorizontal24Regular />
              </div>
            ) : (
              ""
            )}
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  verticalAlign: "center",
                  padding: "0px"
                }}
                onClick={() => {
                  history.replace({
                    pathname: "/templates/advance-edit",
                    state: {
                      name: templates.type,
                      styleId: templates.styleId,
                      selectedValue: selectedValue,
                      confirmedTemplateStyleId: confirmedTemplateStyleId,
                    },
                  });
                }}
              >
                <MenuButton
                  appearance="transparent"
                  icon={<Settings24Filled />}
                  menuIcon={null}
                >
                  {t("templatesSelector.advanceEdit")}
                </MenuButton>
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>

      {isLoading ?
        <div >
          <CardPreview>
            <div style={{
              width: "280px",
              height: "390px",
              border: "1px solid black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              position: "relative",
            }}
            >
              <Spinner label={t("templatesSelector.loading")}   ></Spinner>
            </div>
          </CardPreview>
        </div> :
        <div>
          <CardPreview>
            <img
              src={`${templates.imageData}`}
              alt="Preview of a Word document"
              style={{
                width: "280px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "0.25px solid grey",
                position: "relative",
              }}
            />
          </CardPreview>
        </div>
      }

      {!isLoading &&
        <div style={{ width: "280px", }}>
          <div className={classes.buttonContainerNewWay}>
            <MenuButton
              appearance="primary"
              icon={<ImageSearch24Filled />}
              className={classes.hoverEffect}
              menuIcon={null}
              onClick={() => {
                handlePreview(confirmedTemplateStyleId, selectedValue,)
              }}
              size="large"
            >
              {t("templatesSelector.preview")}
            </MenuButton>
          </div>
        </div>
      }
    </div>

  );
};

export default CurrentCard;
