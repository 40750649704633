export function dateDifference(currentDate, notAfterDate) {
    try {
      const _currentDate = new Date(currentDate);
      const _notAfterDate = new Date(notAfterDate);
  
      if (isNaN(_currentDate.getTime()) || isNaN(_notAfterDate.getTime())) {
        throw new Error("Invalid ISO date format. Please use 'YYYY-MM-DD'.");
      }
  
      const differenceInMilliseconds = _notAfterDate - _currentDate 
  
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  
      return Math.floor(differenceInDays);
    } catch (error) {
      console.error("function dateDifference error: ", error.message);
      return null;
    }
}
  