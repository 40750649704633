import React from "react";
import { useState } from "react";
import { Button, CardPreview, Label } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import { CursorClick24Regular } from "@fluentui/react-icons";
import { PresenceBadge } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    color: "darkgrey",
    marginBottom: "30px",
    width: "150px",
    height: "220px",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#e0e0eb",
  },
  hover: {
    backgroundColor: "#efeff5",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    bottom: "60px",
    left: "40px",
  },
  hoverEffect: {
    transform: "revert",
  },
});

const TemplateCard = ({
  template,
  onSelect,
  isSelected,
  currentSelectedTemplate,
  GotoSelectStyles,
  setSelectedCard
}) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const handleCardClick = () => {
    onSelect(template.styleId);
  };

  function extractNumberFromStyleId(styleId) {
    const cleanedNumber = styleId.replace(/^0+/, '');
    let result;
    if (cleanedNumber.length === 4) {
      const firstPart = cleanedNumber.slice(0, 2);
      result = `${firstPart} `;
    } else if (cleanedNumber.length === 2 || cleanedNumber.length === 1) {
      result = `${cleanedNumber}  `;
    } else if (cleanedNumber.length === 3) {
      const firstPart = cleanedNumber.slice(0, 1);
      result = `${firstPart}  `;
    }

    return result;
  }


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        padding: "7%",
        alignItems: "center",
        borderRadius: "0.375rem",
        paddingBottom: "8%",
      }}
      className={`${classes.root} ${isSelected ? classes.selected : ""}${isHovered ? classes.hover : ""
        }`}
      onClick={handleCardClick}
      onMouseEnter={() => {
        setIsHovered(true)
        setSelectedCard(template?.styleId)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      <CardPreview>
        <img
          src={`${template?.imageData}`}
          alt="Preview of a Word document"
          style={{ width: "150px", height: "200px", border: "1px solid #ddd" }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </CardPreview>
      <div style={{ textAlign: "center", marginTop: "1px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div style={{ display: "flex" }}>
            <Label style={{ marginRight: "5px", paddingTop: "5px" }}>
              {template !== null
                ? ` ${t("templatesSelector.template")} ${extractNumberFromStyleId(template?.styleId)}`
                : null}
            </Label>
            <Label style={{ marginRight: "5px", paddingTop: "6px" }}>
              {currentSelectedTemplate?.styleId === template?.styleId &&
                <PresenceBadge />
              }
            </Label>
          </div>

        </div>
        <div className={classes.buttonContainer}>
          {isHovered ? (
            <Button
              appearance="primary"
              onClick={() => {
                if (isHovered) {
                  GotoSelectStyles(template.styleId)
                } else {

                }
              }
              }
              className={classes.hoverEffect}
            >
              {t("templatesSelector.click")}
              <CursorClick24Regular />
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
